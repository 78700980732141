import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import { THEMES } from "../constants";
import ScrollToTop from "../components/home/ScrollToTop";
import IndexGraph from "../components/home/IndexGraph";

const Index = () => {
  const [mode, setMode] = useState(null);
  const [starredTickers, setStarredTickers] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem("language")) || "en"
  );

  useEffect(() => {
    let lsIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    let isDarkMode = lsIsDarkMode === null ? true : lsIsDarkMode;
    setIsDarkMode(isDarkMode);
    setStarredTickers(JSON.parse(localStorage.getItem("starredTickers")));
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    const htmlElement = document.querySelector("html");
    htmlElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? THEMES.DARK : THEMES.LIGHT
    );
    setMode(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      <Header
        toggleDarkMode={toggleDarkMode}
        lang={lang}
        setLang={setLang}
        mode={mode}
        starredTickers={starredTickers}
      />
      <section className="section" id="stock-penguins-section">
        <IndexGraph lang={lang} mode={mode} />
      </section>
      <ScrollToTop />
    </>
  );
};

export default Index;
