import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip as Tip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Col from "react-bootstrap/Col";
import { localString } from "../../localization/localString";
import { THEME_COLORS } from "../../colors";

const Graph = ({
  index,
  selectedId,
  setSelectedId,
  chartType,
  data,
  description,
  title,
  lang,
  tooltipFormatter,
  yaxisFormatter,
  mode,
  titleValue,
  titleChange,
  subTitle,
  direction = 1,
  titleValue2,
  titleChange2,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tip,
    Filler,
    Legend
  );

  const handleFullScreen = (id) => {
    setSelectedId(id);
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setSelectedId(-1);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const options = (type) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          ticks: {
            callback: function (label, index, labels) {
              if (yaxisFormatter) {
                return yaxisFormatter(label);
              }
              return label;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          // align: 'start',
          color: THEME_COLORS[mode].graph.title,
          text: `${title}`,
          font: {
            size: 18,
          },
        },
        tooltip: {
          titleFont: {
            size: 15,
          },
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipFormatter) {
                return tooltipFormatter(tooltipItem);
              }
              return tooltipItem.formattedValue;
            },
          },
        },
      },
    };
  };

  return (
    <>
      <Col
        sm={12}
        md={6}
        lg={4}
        key={index}
        className={
          "mb-4 gap-2 " +
          (selectedId === index
            ? "chart-full-screen"
            : `position-relative chart-column ${
                mode === "dark" ? "chart-column-dark" : "chart-column-light"
              }`)
        }
        style={{
          backgroundColor: THEME_COLORS[mode].graph.backgroundColor,
          borderRadius: "15px",
        }}
      >
        <div
          style={{
            height: selectedId !== index ? "400px" : "80%",
            width: selectedId === index && "70%",
            margin: "0 auto",
          }}
        >
          <div className="d-flex justify-content-between mb-2">
            <OverlayTrigger placement="left" overlay={<></>}>
              <div>
                <div>{title}</div>
                <div className="d-flex align-items-center">
                  <span className="chart-numbers">{titleValue}</span>
                  {!(
                    isNaN(titleChange) ||
                    titleChange === undefined ||
                    titleChange === null
                  ) && (
                    <span
                      className={`${
                        titleChange * direction >= 0
                          ? "chart-numbers-change-green"
                          : "chart-numbers-change-red"
                      }`}
                    >
                      {titleChange >= 0 ? (
                        <i className="bi bi-arrow-up"></i>
                      ) : (
                        <i className="bi bi-arrow-down"></i>
                      )}
                      {`${Math.abs(titleChange).toFixed(2)}%`}
                    </span>
                  )}

                  {/* {titleValue2 && (
                    <>
                    <span> | </span>
                      <span className="chart-numbers">{titleValue2}</span>
                      <span
                        className={`${
                          titleChange2 >= 0
                            ? "chart-numbers-change-green"
                            : "chart-numbers-change-red"
                        }`}
                      >
                        {titleChange2 >= 0 ? (
                          <i class="bi bi-arrow-up"></i>
                        ) : (
                          <i class="bi bi-arrow-down"></i>
                        )}
                        {`${Math.abs(titleChange2).toFixed(2)}%`}
                      </span>
                    </>
                  )} */}
                </div>
                <div className="chart-sub-title">{subTitle}</div>
              </div>
            </OverlayTrigger>
            <div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="info">{description}</Tooltip>}
              >
                <i className="bi bi-info-circle m-3"></i>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="index">
                    {selectedId === index
                      ? localString[lang].ExitFullScreen
                      : localString[lang].FullScreen}
                  </Tooltip>
                }
              >
                {selectedId === index ? (
                  <i
                    className="bi bi-fullscreen-exit cursor-pointer"
                    onClick={() => handleFullScreen(-1)}
                  ></i>
                ) : (
                  <i
                    className="bi bi-arrows-fullscreen cursor-pointer"
                    onClick={() => handleFullScreen(index)}
                  ></i>
                )}
              </OverlayTrigger>
            </div>
          </div>
          <div
            style={{
              height: selectedId === index ? "90%" : "300px",
              // width: selectedId === index && "70%",
              marginTop: "20px",
            }}
          >
            {chartType === "Line" ? (
              <Line options={options("Line")} data={data()} />
            ) : (
              <Bar options={options("Bar")} data={data()} />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default Graph;
