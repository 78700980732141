import { LANGUAGES } from "../constants";

export const localString = {
  [LANGUAGES[0].value]: {
    // English
    welcome: "Welcome",
    SearchBarPlaceholder: "What's your next 10 bagger going to be?",
    Search: "Search",
    Industry: "Industry",
    Sector: "Sector",
    MarketCap: "Market Cap",
    PE: "P/E",
    PS: "P/S",
    PB: "P/B",
    FCFYield: "FCF Yield",
    PEGRatio: "PEG Ratio",
    OverallRisk: "Overall Risk",
    week52HighPrice: "52 Week High Price",
    PriceGrowthoverthepast5Years: "Price % Growth over the past 5 Years",
    PriceGrowthoverthepast1Years: "Price % Growth over the past 1 Years",
    Profitability: "Profitable? (Quarterly)",
    GrowingRevenue: "Growing Revenue? (Quarterly)",
    GrowingNetProfit: "Growing Net Profit? (Quarterly)",
    ProfitabilityAnnual: "Profitable? (Annually)",
    GrowingRevenueAnnual: "Growing Revenue? (Annually)",
    GrowingNetProfitAnnual: "Growing Net Profit? (Annually)",
    Cash: "Cash",
    Debt: "Debt",
    CashDebtRatio: "Cash Debt Ratio",
    DividendYield: "Dividend Yield",
    PayoutRatio: "Payout Ratio",
    FiveYearAvgDividendYield: "5 Year Avg Dividend Yield",
    DividendGrowthRateoverthepast5Years:
      "Dividend Growth Rate over the past 5 Years",
    FinancialMetrics: "Valuation Summary",
    BalanceSheet: "Financial Metrics",
    QualityOfCompany: "Quality Of Company",
    Dividend: "Dividend",
    comparedToLastQuarter: "Compared to Last Quarter",
    comparedToLastYear: "Compared to Last Year",
    newTab: "New Tab",
    compare: "Compare",
    topCompanies: "Top Companies",
    watchlist: "Watchlist",
    rank: "Rank",
    companyName: "Company Name",
    symbol: "Symbol",
    marketCap: "Market Cap",
    price: "Price",
    today: "Today",
    volume: "Volume",
    country: "Country",
    joinDiscord: "Join Discord",
    joinTelegram: "Join Telegram",
    contactUs: "Contact Us",
    InTheLast365Days: "In the Last 365 Days",
    LastUpdated: "Last Updated",
    pages: "Pages",
    titles: {
      Price: "Price",
      MarketCap: "Market Cap",
      Revenue: "Revenue",
      RevenueVsNetIncome: "Revenue Vs Net Income",
      NetIncome: "Net Income",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Total Expenses",
      SharesIssued: "Shares Outstanding",
      FreeCashFlow: "Free Cash Flow",
      OperatingCashFlow: "Operating Cash Flow",
      Dividend: "Dividend",
      GrossMargin: "Gross Margin",
      CashVsDebt: "Cash Vs Debt",
    },
    description: {
      Price: "Historical stock price of the company over time",
      MarketCap:
        "The total value of a company’s outstanding share of stocks over time",
      Revenue:
        "The total amount of money generated from business activities, sales of goods and services over time",
      RevenueVsNetIncome:
        "A comparison between the company’s total revenue and net profit over time, indicating how sustainable the company’s growth is. A lower ratio may indicate that the company is developing a competitive advantage and taking up market share",
      NetIncome:
        "The profit a company makes after all expenses, taxes, and costs have been deducted from total revenue over time",
      Ebitda:
        "A company’s Earnings Before Interest, Taxes, Depreciation, and Amortization over time. It measures a company's overall financial performance and profitability from core operations",
      Eps: "The portion of a company's profit allocated to each outstanding share of common stock. It indicates how much money shareholders would receive for each share they own",
      TotalExpenses:
        "The sum of all costs incurred by a company in conducting its business, including operating expenses, interest, taxes, etc.. The graph can show the total expenses over time, indicating cost trends and efficiency",
      SharesIssued: "The total number of a company’s shares issued",
      FreeCashFlow:
        "The cash a company generates after accounting for cash outflows to support operations and maintain capital assets. It represents the money available for dividends, debt repayment, or reinvestment",
      OperatingCashFlow:
        "The cash generated from a company’s business operations over time. It shows whether a company can generate enough positive cash flow to maintain and grow its operations",
      Dividend:
        "A portion of a company's earnings is distributed to shareholders, usually in the form of cash payments.",
      GrossMargin:
        "The percentage of revenue remaining after subtracting the cost of goods sold (COGS). It shows how efficiently a company produces and sells its products",
      CashVsDebt:
        "A comparison graph showing the company's cash reserves versus its total debt. It helps assess the company's financial health and liquidity",
    },
    dataDescriptions: {
      MarketCap:
        "The total value of a company's shares of stock; it's calculated by multiplying the stock price by the total number of shares",
      PE: "Price-to-Earnings (P/E) ratio measure of how much investors are willing to pay for each dollar of a company's earnings; calculated by dividing the stock price by earnings per share. A high P/E ratio may indicate that investors expect high future growth rate or the stock may be overvalued",
      PS: "Price-to-Sales (P/S) ratio measures how much investors are paying for each dollar of the company's sales; calculated by dividing the stock price by sales per share. A high P/S ratio may indicate that investors expect high future sales growth or the stock may be overvalued",
      PB: "Price-to-Book (P/S) Ratio is a ratio that compares a company's market value to its book value (assets minus liabilities); calculated by dividing the stock price by the book value per share. A high P/B may indicate strong investor confidence in the company's future performance or that the stock might be overvalued if the company doesn't have strong asset growth",
      FCFYield:
        "Free Cash Flow Yield is a measure of how much free cash flow a company generates relative to its market cap; calculated by dividing free cash flow by the market cap",
      PEGRatio:
        "The Price/earnings to Growth ratio (PEG) valuation metric considers a company's P/E ratio in relation to its earnings growth rate; it helps to see if a stock is over or under-valued. A PEG ratio of 1 suggests fair value, under 1 suggests undervaluation relative to growth rate, and over 1 suggests overvaluation relative to growth rate",
      OverallRisk:
        "An assessment of how risky an investment is, considering factors like volatility, financial stability, and market conditions; lower risk is usually better, but it depends on the investor's risk tolerance",
      week52HighPrice:
        "The highest price a stock has reached in the past year; a stock trading near its 52-week high may indicate strong recent performance, but it could also suggest it’s overvalued",
      ProfitabilityAnnual:
        "Whether the company has made more money than it spent over the past year; profitability is generally a good sign of a company’s financial health",
      GrowingRevenueAnnual:
        "Whether the company’s revenue (sales) has increased compared to the previous year; consistent revenue growth is usually a positive sign of business expansion",
      GrowingNetProfitAnnual:
        "Whether the company’s net profit (income after expenses) has increased compared to the previous year; growing net profit is a strong indicator of improving financial performance",
      PriceGrowthoverthepast5Years:
        "The percentage increase in a stock’s price over the last five years; a higher percentage indicates better long-term performance",
      PriceGrowthoverthepast1Years:
        "The percentage increase in a stock’s price over the last year; strong short-term growth is positive but should be considered in context with other factors",
      Cash: "The total amount of liquid money the company has available to use immediately; more cash is generally better for financial flexibility",
      Debt: "The total amount of money the company owes to lenders; higher debt can be risky if the company can't generate enough income to pay it off",
      CashDebtRatio:
        "A measure of a company's ability to pay off its debt with its cash on hand; a higher ratio indicates better financial health",
      Profitability:
        "Whether the company made more money than it spent in the most recent quarter; being profitable quarterly shows the company is consistently generating income",
      GrowingRevenue:
        "Whether the company’s revenue has increased compared to the previous quarter; continuous revenue growth is a sign of ongoing business success",
      GrowingNetProfit:
        "Whether the company’s net profit has increased compared to the previous quarter; growing net profit quarterly indicates improving financial performance in the short term",
      DividendYield:
        "The annual dividend payment a company gives to its shareholders, expressed as a percentage of its stock price; a higher yield can indicate more income from dividends relative to the stock price",
      PayoutRatio:
        "The percentage of a company’s earnings paid out as dividends to shareholders; a lower ratio suggests the company is retaining more earnings for growth, while a very high ratio might indicate the dividend could be unsustainable",
      FiveYearAvgDividendYield:
        "The average dividend yield a company has offered over the past five years; it helps investors see how consistent or stable the dividend payments have been over time",
    },
    labels: {
      TotalCash: "Total Cash",
      TotalDebt: "Total Debt",
      Revenue: "Revenue",
      NetIncome: "Net Income",
    },
    Quarterly: "Quarterly",
    Annually: "Annually",
    FullScreen: "Full Screen",
    ExitFullScreen: "Exit Full Screen",
    homeMetaTitle: "StockPenguins | Free Global Equities Stock Scanner",
    homeMetaDescription:
      "Discover the ultimate free stock scanner app for US equities like Apple, Tesla, Amazon, and Microsoft. Access detailed metrics such as Price, Market Cap, Revenue, Net Income, EBITDA, Free Cash Flow, EPS, Dividend, P/E Ratio, and more. Analyze and compare stocks effortlessly to make informed investment decisions. Search now!",
    pageMetaTitle: "Stock Metrics | P/E, Revenue & Market Capitalization",
    pageMetaDescription:
      "Discover free metrics, including Price, Market Cap, Revenue, Net Income, EBITDA, Free Cash Flow, EPS, Dividend, P/E Ratio, and more. Stay informed about Apple's financial performance. Explore now!",
  },
  [LANGUAGES[1].value]: {
    // Chinese
    welcome: "欢迎",
    SearchBarPlaceholder: "你的下一个十倍股是什么？",
    Search: "搜索",
    Industry: "行业",
    Sector: "部门",
    MarketCap: "市值",
    PE: "市盈率",
    PS: "市销率",
    PB: "市净率",
    FCFYield: "自由现金流收益率",
    PEGRatio: "市盈增长比率",
    OverallRisk: "总体风险",
    week52HighPrice: "52周最高价",
    PriceGrowthoverthepast5Years: "过去5年价格增长百分比",
    PriceGrowthoverthepast1Years: "过去1年价格增长百分比",
    Profitability: "盈利能力？（季度）",
    GrowingRevenue: "增长收入？（季度）",
    GrowingNetProfit: "增长净利润？（季度）",
    ProfitabilityAnnual: "盈利能力？（年度）",
    GrowingRevenueAnnual: "增长收入？（年度）",
    GrowingNetProfitAnnual: "增长净利润？（年度）",
    FinancialMetrics: "估值摘要",
    BalanceSheet: "财务指标",
    Cash: "现金",
    Debt: "债务",
    CashDebtRatio: "现金债务比",
    DividendYield: "股息率",
    PayoutRatio: "派息率",
    FiveYearAvgDividendYield: "过去5年平均股息率",
    DividendGrowthRateoverthepast5Years: "过去5年股息增长率",
    QualityOfCompany: "公司质量",
    Dividend: "股息",
    comparedToLastQuarter: "与上季度相比",
    comparedToLastYear: "与去年相比",
    newTab: "新标签页",
    compare: "比较",
    topCompanies: "顶级公司",
    watchlist: "关注列表",
    rank: "排名",
    companyName: "公司名称",
    symbol: "股票代码",
    marketCap: "市值",
    price: "价格",
    today: "今天",
    volume: "成交量",
    country: "国家",
    joinDiscord: "加入不和谐",
    joinTelegram: "加入电报群",
    contactUs: "联系我们",
    InTheLast365Days: "在过去的365天里",
    LastUpdated: "最后更新",
    pages: "页数",

    titles: {
      Price: "价格",
      MarketCap: "市值",
      Revenue: "收入",
      RevenueVsNetIncome: "收入与净收入对比",
      NetIncome: "净收入",
      Ebitda: "EBITDA",
      Eps: "每股收益",
      TotalExpenses: "总费用",
      SharesIssued: "流通股数",
      FreeCashFlow: "自由现金流",
      OperatingCashFlow: "运营现金流",
      Dividend: "股息",
      GrossMargin: "毛利率",
      CashVsDebt: "现金与债务对比",
    },
    description: {
      Price: "公司历年股票价格",
      MarketCap: "公司已发行股票的总价值历年变化",
      Revenue: "通过业务活动、商品和服务销售产生的总收入历年变化",
      RevenueVsNetIncome:
        "公司总收入与净利润的对比，表明公司增长的可持续性。较低的比率可能表明公司正在建立竞争优势并占据市场份额",
      NetIncome: "公司在扣除所有费用、税款和成本后的利润历年变化",
      Ebitda:
        "公司历年息税折旧及摊销前的收益。它衡量公司核心运营的整体财务表现和盈利能力",
      Eps: "分配给每股普通股的公司利润部分。它表明股东每持有一股将获得多少收益",
      TotalExpenses:
        "公司开展业务所产生的所有成本的总和，包括运营费用、利息、税款等。该图可以显示历年总费用，指示成本趋势和效率",
      SharesIssued: "公司已发行的总股数",
      FreeCashFlow:
        "在支持运营和维护资本资产的现金流出后公司产生的现金。它代表可用于股息、偿还债务或再投资的资金",
      OperatingCashFlow:
        "公司历年业务运营产生的现金。它表明公司是否能够产生足够的正现金流以维持和增长其运营",
      Dividend: "公司盈利的一部分以现金支付的形式分配给股东。",
      GrossMargin:
        "在扣除销售成本（COGS）后的收入百分比。它表明公司生产和销售产品的效率",
      CashVsDebt:
        "公司现金储备与总债务的对比图。它有助于评估公司的财务健康状况和流动性",
    },
    dataDescriptions: {
      MarketCap: "公司股票的总价值；通过将股票价格乘以总股份数量计算得出",
      PE: "市盈率 (P/E 比率) 衡量投资者愿意为公司每美元的收益支付多少；通过将股票价格除以每股收益计算得出。高市盈率可能表明投资者预期未来增长率较高，或者股票可能被高估",
      PS: "市销率 (P/S 比率) 衡量投资者为公司每美元的销售额支付多少；通过将股票价格除以每股销售额计算得出。高市销率可能表明投资者预期未来销售增长较高，或者股票可能被高估",
      PB: "市净率 (P/B 比率) 是将公司的市场价值与其账面价值（资产减去负债）进行比较的比率；通过将股票价格除以每股账面价值计算得出。高市净率可能表明投资者对公司未来表现有强烈信心，或者如果公司没有强劲的资产增长，股票可能被高估",
      FCFYield:
        "自由现金流收益率是衡量公司相对于其市值产生的自由现金流量的指标；通过将自由现金流除以市值计算得出",
      PEGRatio:
        "市盈率与增长比率 (PEG 比率) 是一种估值指标，它将公司的市盈率与其收益增长率进行比较；有助于判断股票是被高估还是低估。PEG 比率为 1 表示公允价值，小于 1 表示相对于增长率被低估，大于 1 表示相对于增长率被高估",
      OverallRisk:
        "评估投资风险水平，考虑因素如波动性、财务稳定性和市场状况；通常风险越低越好，但这取决于投资者的风险承受能力",
      week52HighPrice:
        "过去一年中股票达到的最高价格；股票交易接近52周高点可能表明近期表现强劲，但也可能表明被高估",
      ProfitabilityAnnual:
        "公司在过去一年中是否赚的钱比花的钱多；盈利能力通常是公司财务健康的良好标志",
      GrowingRevenueAnnual:
        "公司收入（销售额）与前一年相比是否增加；持续的收入增长通常是业务扩展的积极信号",
      GrowingNetProfitAnnual:
        "公司净利润（扣除费用后的收入）与前一年相比是否增加；净利润增长是财务表现改善的强劲指标",
      PriceGrowthoverthepast5Years:
        "过去五年股票价格的涨幅；较高的百分比表明长期表现更好",
      PriceGrowthoverthepast1Years:
        "过去一年股票价格的涨幅；短期内的强劲增长是积极的，但应结合其他因素考虑",
      Cash: "公司可立即使用的流动资金总额；更多的现金通常对财务灵活性更有利",
      Debt: "公司欠贷方的总金额；如果公司不能产生足够的收入来偿还债务，较高的负债可能存在风险",
      CashDebtRatio:
        "衡量公司使用手头现金偿还债务的能力；较高的比率表明财务健康状况较好",
      Profitability:
        "公司在最近一个季度是否赚的钱比花的钱多；季度盈利能力表明公司持续产生收入",
      GrowingRevenue:
        "公司收入与上季度相比是否增加；持续的收入增长是业务成功的标志",
      GrowingNetProfit:
        "公司净利润与上季度相比是否增加；季度净利润的增长表明短期财务表现正在改善",
      DividendYield:
        "公司向股东支付的年度股息金额，占其股价的百分比；较高的收益率表明相对于股票价格，股息收入更多",
      PayoutRatio:
        "公司收益中支付给股东的股息比例；较低的比率表明公司保留更多的收益用于增长，而非常高的比率可能表明股息不可持续",
      FiveYearAvgDividendYield:
        "公司在过去五年中提供的平均股息收益率；有助于投资者了解股息支付的持续性或稳定性",
    },
    labels: {
      TotalCash: "总现金",
      TotalDebt: "总债务",
      Revenue: "收入",
      NetIncome: "净收入",
    },
    Quarterly: "季度",
    Annually: "每年",
    FullScreen: "全屏",
    ExitFullScreen: "退出全屏",
    homeMetaTitle: "StockPenguins | 免费全球股票扫描器",
    homeMetaDescription:
      "发现终极免费股票扫描应用程序，适用于Apple、Tesla、Amazon和Microsoft等美国股票。访问详细指标，如价格、市值、收入、净收入、EBITDA、自由现金流、每股收益、股息、市盈率等。轻松分析和比较股票，做出明智的投资决策。立即搜索！",
    pageMetaTitle: "股票指标 | 市盈率、收入和市值",
    pageMetaDescription:
      "发现免费指标，包括价格、市值、收入、净收入、EBITDA、自由现金流、每股收益、股息、市盈率等。了解Apple的财务表现。立即探索！",
  },
  [LANGUAGES[2].value]: {
    // Spanish
    welcome: "Bienvenido",
    SearchBarPlaceholder: "¿Cuál será tu próximo 10 veces ganador?",
    Search: "Buscar",
    Industry: "Industria",
    Sector: "Sector",
    MarketCap: "Capitalización de Mercado",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Rendimiento de FCF",
    PEGRatio: "Ratio PEG",
    OverallRisk: "Riesgo General",
    week52HighPrice: "Precio Máximo de 52 Semanas",
    PriceGrowthoverthepast5Years:
      "Crecimiento del Precio en los Últimos 5 Años",
    PriceGrowthoverthepast1Years: "Crecimiento del Precio en el Último Año",
    Profitability: "¿Rentable? (Trimestral)",
    GrowingRevenue: "¿Crecimiento de ingresos? (Trimestral)",
    GrowingNetProfit: "¿Crecimiento del beneficio neto? (Trimestral)",
    ProfitabilityAnnual: "¿Rentable? (Anual)",
    GrowingRevenueAnnual: "¿Crecimiento de ingresos? (Anual)",
    GrowingNetProfitAnnual: "¿Crecimiento del beneficio neto? (Anual)",
    FinancialMetrics: "Resumen de valoración",
    BalanceSheet: "Métricas financieras",
    Cash: "Efectivo",
    Debt: "Deuda",
    CashDebtRatio: "Ratio Efectivo / Deuda",
    DividendYield: "Rendimiento por Dividendo",
    PayoutRatio: "Ratio de Distribución",
    FiveYearAvgDividendYield: "Promedio de Rendimiento por Dividendo en 5 Años",
    DividendGrowthRateoverthepast5Years:
      "Tasa de Crecimiento del Dividendo en los Últimos 5 Años",
    QualityOfCompany: "Calidad de la Empresa",
    Dividend: "Dividendo",
    comparedToLastQuarter: "Comparado con el último trimestre",
    comparedToLastYear: "Comparado con el año pasado",
    newTab: "Nueva pestaña",
    compare: "Comparar",
    topCompanies: "Empresas principales",
    watchlist: "Lista de seguimiento",
    rank: "Rango",
    companyName: "Nombre de la empresa",
    symbol: "Símbolo",
    marketCap: "Capitalización de mercado",
    price: "Precio",
    today: "Hoy",
    volume: "Volumen",
    country: "País",
    joinDiscord: "Unirse a Discord",
    joinTelegram: "Unirse a Telegram",
    contactUs: "Contáctenos",
    InTheLast365Days: "En los últimos 365 días",
    LastUpdated: "Última actualización",
    pages: "Páginas",

    titles: {
      Price: "Precio",
      MarketCap: "Capitalización de Mercado",
      Revenue: "Ingresos",
      RevenueVsNetIncome: "Ingresos vs. Ingresos Netos",
      NetIncome: "Ingresos Netos",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Gastos Totales",
      SharesIssued: "Acciones en circulación",
      FreeCashFlow: "Flujo de Caja Libre",
      OperatingCashFlow: "Flujo de Caja Operativo",
      Dividend: "Dividendo",
      GrossMargin: "Margen Bruto",
      CashVsDebt: "Efectivo vs. Deuda",
    },
    description: {
      Price:
        "Precio histórico de las acciones de la empresa a lo largo del tiempo",
      MarketCap:
        "El valor total de las acciones en circulación de una empresa a lo largo del tiempo",
      Revenue:
        "La cantidad total de dinero generada por actividades comerciales, ventas de bienes y servicios a lo largo del tiempo",
      RevenueVsNetIncome:
        "Una comparación entre los ingresos totales y el beneficio neto de la empresa a lo largo del tiempo, que indica la sostenibilidad del crecimiento de la empresa. Una relación más baja puede indicar que la empresa está desarrollando una ventaja competitiva y ganando cuota de mercado",
      NetIncome:
        "El beneficio que una empresa obtiene después de deducir todos los gastos, impuestos y costos de los ingresos totales a lo largo del tiempo",
      Ebitda:
        "Los beneficios de una empresa antes de intereses, impuestos, depreciación y amortización a lo largo del tiempo. Mide el rendimiento financiero general y la rentabilidad de una empresa de sus operaciones principales",
      Eps: "La parte del beneficio de una empresa asignada a cada acción en circulación. Indica cuánto dinero recibirían los accionistas por cada acción que poseen",
      TotalExpenses:
        "La suma de todos los costos incurridos por una empresa en la realización de su negocio, incluidos los gastos operativos, intereses, impuestos, etc. El gráfico puede mostrar los gastos totales a lo largo del tiempo, indicando tendencias de costos y eficiencia",
      SharesIssued: "El número total de acciones emitidas por una empresa",
      FreeCashFlow:
        "El efectivo que una empresa genera después de tener en cuenta las salidas de efectivo para apoyar las operaciones y mantener los activos de capital. Representa el dinero disponible para dividendos, pago de deudas o reinversión",
      OperatingCashFlow:
        "El efectivo generado por las operaciones comerciales de una empresa a lo largo del tiempo. Muestra si una empresa puede generar suficiente flujo de caja positivo para mantener y crecer sus operaciones",
      Dividend:
        "Una parte de las ganancias de una empresa distribuida a los accionistas, generalmente en forma de pagos en efectivo.",
      GrossMargin:
        "El porcentaje de los ingresos que queda después de restar el costo de los bienes vendidos (COGS). Muestra la eficiencia con la que una empresa produce y vende sus productos",
      CashVsDebt:
        "Un gráfico comparativo que muestra las reservas de efectivo de la empresa frente a su deuda total. Ayuda a evaluar la salud financiera y la liquidez de la empresa",
    },
    dataDescriptions: {
      MarketCap:
        "El valor total de las acciones de una empresa; se calcula multiplicando el precio de la acción por el número total de acciones",
      PE: "La relación precio/beneficio (P/E) mide cuánto están dispuestos a pagar los inversores por cada dólar de ganancias de una empresa; se calcula dividiendo el precio de la acción por las ganancias por acción. Un alto P/E puede indicar que los inversores esperan una alta tasa de crecimiento futuro o que la acción puede estar sobrevalorada",
      PS: "La relación precio/ventas (P/S) mide cuánto están pagando los inversores por cada dólar de ventas de la empresa; se calcula dividiendo el precio de la acción por las ventas por acción. Un alto P/S puede indicar que los inversores esperan un alto crecimiento de ventas futuras o que la acción puede estar sobrevalorada",
      PB: "La relación precio/valor contable (P/B) es una relación que compara el valor de mercado de una empresa con su valor contable (activos menos pasivos); se calcula dividiendo el precio de la acción por el valor contable por acción. Un alto P/B puede indicar una fuerte confianza de los inversores en el rendimiento futuro de la empresa o que la acción podría estar sobrevalorada si la empresa no tiene un fuerte crecimiento de activos",
      FCFYield:
        "El rendimiento de flujo de caja libre es una medida de cuánto flujo de caja libre genera una empresa en relación con su capitalización de mercado; se calcula dividiendo el flujo de caja libre por la capitalización de mercado",
      PEGRatio:
        "La relación precio/beneficio respecto al crecimiento (PEG) es una métrica de valoración que considera la relación P/E de una empresa en relación con su tasa de crecimiento de ganancias; ayuda a ver si una acción está sobrevalorada o infravalorada. Una relación PEG de 1 sugiere un valor justo, por debajo de 1 sugiere infravaloración en relación con la tasa de crecimiento, y por encima de 1 sugiere sobrevaloración en relación con la tasa de crecimiento",
      OverallRisk:
        "Una evaluación de cuán arriesgada es una inversión, considerando factores como la volatilidad, la estabilidad financiera y las condiciones del mercado; un riesgo más bajo suele ser mejor, pero depende de la tolerancia al riesgo del inversor",
      week52HighPrice:
        "El precio más alto que ha alcanzado una acción en el último año; una acción que cotiza cerca de su máximo de 52 semanas puede indicar un rendimiento reciente fuerte, pero también podría sugerir que está sobrevalorada",
      ProfitabilityAnnual:
        "Si la empresa ha ganado más dinero del que ha gastado en el último año; la rentabilidad es generalmente una buena señal de la salud financiera de una empresa",
      GrowingRevenueAnnual:
        "Si los ingresos (ventas) de la empresa han aumentado en comparación con el año anterior; el crecimiento constante de los ingresos suele ser una señal positiva de expansión del negocio",
      GrowingNetProfitAnnual:
        "Si la ganancia neta de la empresa (ingresos después de gastos) ha aumentado en comparación con el año anterior; el crecimiento de la ganancia neta es un fuerte indicador de la mejora del rendimiento financiero",
      PriceGrowthoverthepast5Years:
        "El aumento porcentual en el precio de una acción en los últimos cinco años; un porcentaje más alto indica un mejor rendimiento a largo plazo",
      PriceGrowthoverthepast1Years:
        "El aumento porcentual en el precio de una acción en el último año; un fuerte crecimiento a corto plazo es positivo, pero debe considerarse en el contexto de otros factores",
      Cash: "La cantidad total de dinero líquido que la empresa tiene disponible para usar de inmediato; más efectivo generalmente es mejor para la flexibilidad financiera",
      Debt: "El monto total de dinero que la empresa debe a los prestamistas; una mayor deuda puede ser arriesgada si la empresa no puede generar suficientes ingresos para pagarla",
      CashDebtRatio:
        "Una medida de la capacidad de una empresa para pagar su deuda con el efectivo disponible; una relación más alta indica una mejor salud financiera",
      Profitability:
        "Si la empresa ha ganado más dinero del que ha gastado en el trimestre más reciente; ser rentable trimestralmente muestra que la empresa está generando ingresos de manera consistente",
      GrowingRevenue:
        "Si los ingresos de la empresa han aumentado en comparación con el trimestre anterior; el crecimiento continuo de los ingresos es una señal de éxito continuo del negocio",
      GrowingNetProfit:
        "Si la ganancia neta de la empresa ha aumentado en comparación con el trimestre anterior; el crecimiento de la ganancia neta trimestral indica una mejora del rendimiento financiero a corto plazo",
      DividendYield:
        "El pago anual de dividendos que una empresa otorga a sus accionistas, expresado como un porcentaje de su precio de acción; un rendimiento más alto puede indicar más ingresos por dividendos en relación con el precio de la acción",
      PayoutRatio:
        "El porcentaje de las ganancias de una empresa que se paga como dividendos a los accionistas; una relación más baja sugiere que la empresa está reteniendo más ganancias para el crecimiento, mientras que una relación muy alta podría indicar que el dividendo podría no ser sostenible",
      FiveYearAvgDividendYield:
        "El rendimiento promedio de dividendos que una empresa ha ofrecido en los últimos cinco años; ayuda a los inversores a ver cuán consistentes o estables han sido los pagos de dividendos a lo largo del tiempo",
    },
    labels: {
      TotalCash: "Efectivo Total",
      TotalDebt: "Deuda Total",
      Revenue: "Ingresos",
      NetIncome: "Ingresos Netos",
    },
    Quarterly: "Trimestral",
    Annually: "Anualmente",
    FullScreen: "Pantalla completa",
    ExitFullScreen: "Salir de pantalla completa",
    homeMetaTitle: "StockPenguins | Escáner de Acciones Globales Gratuito",
    homeMetaDescription:
      "Descubre la mejor aplicación gratuita de escáner de acciones para las acciones estadounidenses como Apple, Tesla, Amazon y Microsoft. Accede a métricas detalladas como Precio, Capitalización de Mercado, Ingresos, Ingreso Neto, EBITDA, Flujo de Caja Libre, EPS, Dividendo, Ratio P/E y más. Analiza y compara acciones sin esfuerzo para tomar decisiones de inversión informadas. ¡Busca ahora!",
    pageMetaTitle:
      "Métricas de Acciones | P/E, Ingresos y Capitalización de Mercado",
    pageMetaDescription:
      "Descubre métricas gratuitas, incluyendo Precio, Capitalización de Mercado, Ingresos, Ingreso Neto, EBITDA, Flujo de Caja Libre, EPS, Dividendo, Ratio P/E y más. Mantente informado sobre el rendimiento financiero de Apple. ¡Explora ahora!",
  },
  [LANGUAGES[3].value]: {
    // French
    welcome: "Bienvenue",
    SearchBarPlaceholder: "Quel sera votre prochain décupleur?",
    Search: "Rechercher",
    Industry: "Industrie",
    Sector: "Secteur",
    MarketCap: "Capitalisation Boursière",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Rendement du FCF",
    PEGRatio: "Ratio PEG",
    OverallRisk: "Risque Global",
    week52HighPrice: "Prix Maximum sur 52 Semaines",
    PriceGrowthoverthepast5Years:
      "Croissance du Prix sur les 5 Dernières Années",
    PriceGrowthoverthepast1Years: "Croissance du Prix sur la Dernière Année",
    Profitability: "Rentable ? (Trimestriel)",
    GrowingRevenue: "Croissance des revenus ? (Trimestriel)",
    GrowingNetProfit: "Croissance du bénéfice net ? (Trimestriel)",
    ProfitabilityAnnual: "Rentable ? (Annuel)",
    GrowingRevenueAnnual: "Croissance des revenus ? (Annuel)",
    GrowingNetProfitAnnual: "Croissance du bénéfice net ? (Annuel)",
    FinancialMetrics: "Résumé de l'évaluation",
    BalanceSheet: "Métriques financières",
    Cash: "Trésorerie",
    Debt: "Dette",
    CashDebtRatio: "Ratio Trésorerie / Dette",
    DividendYield: "Rendement du Dividende",
    PayoutRatio: "Ratio de Distribution",
    FiveYearAvgDividendYield: "Rendement Moyen du Dividende sur 5 Ans",
    DividendGrowthRateoverthepast5Years:
      "Taux de Croissance du Dividende sur les 5 Dernières Années",
    QualityOfCompany: "Qualité de l'Entreprise",
    Dividend: "Dividende",
    comparedToLastQuarter: "Par rapport au dernier trimestre",
    comparedToLastYear: "Par rapport à l'année dernière",
    newTab: "Nouvel onglet",
    compare: "Comparer",
    topCompanies: "Meilleures entreprises",
    watchlist: "Liste de surveillance",
    rank: "Rang",
    companyName: "Nom de l'entreprise",
    symbol: "Symbole",
    marketCap: "Capitalisation boursière",
    price: "Prix",
    today: "Aujourd'hui",
    volume: "Volume",
    country: "Pays",
    joinDiscord: "Rejoindre Discord",
    joinTelegram: "Rejoindre Telegram",
    contactUs: "Contactez-nous",
    InTheLast365Days: "Au cours des 365 derniers jours",
    LastUpdated: "Dernière mise à jour",
    pages: "Pages",

    titles: {
      Price: "Prix",
      MarketCap: "Capitalisation Boursière",
      Revenue: "Revenu",
      RevenueVsNetIncome: "Revenu vs. Revenu Net",
      NetIncome: "Revenu Net",
      Ebitda: "EBITDA",
      Eps: "BPA",
      TotalExpenses: "Dépenses Totales",
      SharesIssued: "Actions en circulation",
      FreeCashFlow: "Flux de Trésorerie Libre",
      OperatingCashFlow: "Flux de Trésorerie Opérationnel",
      Dividend: "Dividende",
      GrossMargin: "Marge Brute",
      CashVsDebt: "Trésorerie vs. Dette",
    },
    description: {
      Price: "Prix historique des actions de l'entreprise au fil du temps",
      MarketCap:
        "La valeur totale des actions en circulation d'une entreprise au fil du temps",
      Revenue:
        "Le montant total d'argent généré par les activités commerciales, la vente de biens et services au fil du temps",
      RevenueVsNetIncome:
        "Une comparaison entre le revenu total et le bénéfice net de l'entreprise au fil du temps, indiquant la durabilité de la croissance de l'entreprise. Un ratio plus faible peut indiquer que l'entreprise développe un avantage concurrentiel et gagne des parts de marché",
      NetIncome:
        "Le bénéfice qu'une entreprise réalise après déduction de toutes les dépenses, impôts et coûts du revenu total au fil du temps",
      Ebitda:
        "Les bénéfices avant intérêts, impôts, dépréciation et amortissement d'une entreprise au fil du temps. Il mesure la performance financière globale et la rentabilité d'une entreprise à partir de ses opérations principales",
      Eps: "La part du bénéfice d'une entreprise attribuée à chaque action en circulation. Il indique combien d'argent les actionnaires recevraient pour chaque action qu'ils possèdent",
      TotalExpenses:
        "La somme de tous les coûts encourus par une entreprise dans la conduite de ses affaires, y compris les dépenses opérationnelles, les intérêts, les impôts, etc. Le graphique peut montrer les dépenses totales au fil du temps, indiquant les tendances des coûts et l'efficacité",
      SharesIssued: "Le nombre total d'actions émises par une entreprise",
      FreeCashFlow:
        "La trésorerie générée par une entreprise après avoir pris en compte les sorties de trésorerie pour soutenir les opérations et maintenir les actifs de capital. Il représente l'argent disponible pour les dividendes, le remboursement de la dette ou la réinvestissement",
      OperatingCashFlow:
        "La trésorerie générée par les opérations commerciales d'une entreprise au fil du temps. Il montre si une entreprise peut générer suffisamment de flux de trésorerie positif pour maintenir et développer ses opérations",
      Dividend:
        "Une partie des bénéfices d'une entreprise distribuée aux actionnaires, généralement sous forme de paiements en espèces.",
      GrossMargin:
        "Le pourcentage du revenu restant après déduction du coût des biens vendus (COGS). Il montre l'efficacité avec laquelle une entreprise produit et vend ses produits",
      CashVsDebt:
        "Un graphique comparatif montrant les réserves de trésorerie de l'entreprise par rapport à sa dette totale. Il aide à évaluer la santé financière et la liquidité de l'entreprise",
    },
    dataDescriptions: {
      MarketCap:
        "La valeur totale des actions d'une entreprise ; elle est calculée en multipliant le prix de l'action par le nombre total d'actions",
      PE: "Le ratio cours/bénéfice (P/E) mesure combien les investisseurs sont prêts à payer pour chaque dollar de bénéfice d'une entreprise ; il est calculé en divisant le prix de l'action par le bénéfice par action. Un ratio P/E élevé peut indiquer que les investisseurs s'attendent à une forte croissance future ou que l'action pourrait être surévaluée",
      PS: "Le ratio cours/ventes (P/S) mesure combien les investisseurs paient pour chaque dollar de ventes de l'entreprise ; il est calculé en divisant le prix de l'action par les ventes par action. Un ratio P/S élevé peut indiquer que les investisseurs s'attendent à une forte croissance des ventes futures ou que l'action pourrait être surévaluée",
      PB: "Le ratio cours/valeur comptable (P/B) compare la valeur marchande d'une entreprise à sa valeur comptable (actifs moins passifs) ; il est calculé en divisant le prix de l'action par la valeur comptable par action. Un ratio P/B élevé peut indiquer une forte confiance des investisseurs dans la performance future de l'entreprise ou que l'action pourrait être surévaluée si l'entreprise ne connaît pas une forte croissance de ses actifs",
      FCFYield:
        "Le rendement des flux de trésorerie disponibles est une mesure de la quantité de flux de trésorerie disponibles générée par une entreprise par rapport à sa capitalisation boursière ; il est calculé en divisant les flux de trésorerie disponibles par la capitalisation boursière",
      PEGRatio:
        "Le ratio cours/bénéfice/ratio de croissance (PEG) est une métrique d'évaluation qui prend en compte le ratio P/E d'une entreprise par rapport à son taux de croissance des bénéfices ; il aide à voir si une action est surévaluée ou sous-évaluée. Un ratio PEG de 1 suggère une juste valeur, en dessous de 1 suggère une sous-évaluation par rapport au taux de croissance, et au-dessus de 1 suggère une surévaluation par rapport au taux de croissance",
      OverallRisk:
        "Une évaluation du niveau de risque d'un investissement, en tenant compte de facteurs tels que la volatilité, la stabilité financière et les conditions du marché ; un risque plus faible est généralement préférable, mais cela dépend de la tolérance au risque de l'investisseur",
      week52HighPrice:
        "Le prix le plus élevé qu'une action a atteint au cours de l'année écoulée ; une action cotée près de son plus haut sur 52 semaines peut indiquer une performance récente solide, mais cela pourrait aussi suggérer qu'elle est surévaluée",
      ProfitabilityAnnual:
        "Si l'entreprise a gagné plus d'argent qu'elle n'en a dépensé au cours de l'année écoulée ; la rentabilité est généralement un bon signe de la santé financière d'une entreprise",
      GrowingRevenueAnnual:
        "Si les revenus (ventes) de l'entreprise ont augmenté par rapport à l'année précédente ; une croissance continue des revenus est généralement un signe positif de l'expansion de l'entreprise",
      GrowingNetProfitAnnual:
        "Si le bénéfice net de l'entreprise (revenus après dépenses) a augmenté par rapport à l'année précédente ; la croissance du bénéfice net est un indicateur fort de l'amélioration de la performance financière",
      PriceGrowthoverthepast5Years:
        "L'augmentation en pourcentage du prix d'une action au cours des cinq dernières années ; un pourcentage plus élevé indique une meilleure performance à long terme",
      PriceGrowthoverthepast1Years:
        "L'augmentation en pourcentage du prix d'une action au cours de la dernière année ; une forte croissance à court terme est positive mais doit être considérée dans le contexte d'autres facteurs",
      Cash: "Le montant total d'argent liquide que l'entreprise a immédiatement disponible ; plus de liquidités est généralement mieux pour la flexibilité financière",
      Debt: "Le montant total d'argent que l'entreprise doit aux prêteurs ; une dette plus élevée peut être risquée si l'entreprise ne peut pas générer suffisamment de revenus pour la rembourser",
      CashDebtRatio:
        "Une mesure de la capacité d'une entreprise à rembourser sa dette avec les liquidités disponibles ; un ratio plus élevé indique une meilleure santé financière",
      Profitability:
        "Si l'entreprise a gagné plus d'argent qu'elle n'en a dépensé au cours du trimestre le plus récent ; être rentable chaque trimestre montre que l'entreprise génère des revenus de manière constante",
      GrowingRevenue:
        "Si les revenus de l'entreprise ont augmenté par rapport au trimestre précédent ; une croissance continue des revenus est un signe de succès continu de l'entreprise",
      GrowingNetProfit:
        "Si le bénéfice net de l'entreprise a augmenté par rapport au trimestre précédent ; la croissance du bénéfice net trimestriel indique une amélioration de la performance financière à court terme",
      DividendYield:
        "Le paiement annuel de dividendes qu'une entreprise verse à ses actionnaires, exprimé en pourcentage de son prix d'action ; un rendement plus élevé peut indiquer plus de revenus de dividendes par rapport au prix de l'action",
      PayoutRatio:
        "Le pourcentage des bénéfices d'une entreprise versé en dividendes aux actionnaires ; un ratio plus faible suggère que l'entreprise conserve plus de bénéfices pour la croissance, tandis qu'un ratio très élevé pourrait indiquer que le dividende pourrait ne pas être durable",
      FiveYearAvgDividendYield:
        "Le rendement moyen des dividendes qu'une entreprise a offert au cours des cinq dernières années ; cela aide les investisseurs à voir à quel point les paiements de dividendes ont été constants ou stables au fil du temps",
    },
    labels: {
      TotalCash: "Trésorerie Totale",
      TotalDebt: "Dette Totale",
      Revenue: "Revenu",
      NetIncome: "Revenu Net",
    },
    Quarterly: "Trimestriel",
    Annually: "Annuellement",
    FullScreen: "Plein écran",
    ExitFullScreen: "Quitter le plein écran",
    homeMetaTitle: "StockPenguins | Scanner de Stock Global Gratuit",
    homeMetaDescription:
      "Découvrez l'application ultime de scanner de stock gratuit pour les actions américaines comme Apple, Tesla, Amazon et Microsoft. Accédez à des métriques détaillées telles que le Prix, la Capitalisation Boursière, les Revenus, le Revenu Net, l'EBITDA, le Flux de Trésorerie Libre, le BPA, le Dividende, le Ratio P/E et plus encore. Analysez et comparez les actions sans effort pour prendre des décisions d'investissement éclairées. Recherchez maintenant !",
    pageMetaTitle:
      "Métriques de Stock | P/E, Revenus et Capitalisation Boursière",
    pageMetaDescription:
      "Découvrez des métriques gratuites, y compris le Prix, la Capitalisation Boursière, les Revenus, le Revenu Net, l'EBITDA, le Flux de Trésorerie Libre, le BPA, le Dividende, le Ratio P/E et plus encore. Restez informé sur la performance financière d'Apple. Explorez maintenant !",
  },
  [LANGUAGES[4].value]: {
    // German
    welcome: "Willkommen",
    SearchBarPlaceholder: "Was wird dein nächster 10-Bagger sein?",
    Search: "Suche",
    Industry: "Industrie",
    Sector: "Sektor",
    MarketCap: "Marktkapitalisierung",
    PE: "KGV",
    PS: "KUV",
    PB: "KBV",
    FCFYield: "FCF-Rendite",
    PEGRatio: "PEG-Verhältnis",
    OverallRisk: "Gesamtrisiko",
    week52HighPrice: "52-Wochen-Höchstkurs",
    PriceGrowthoverthepast5Years: "Preiswachstum in den letzten 5 Jahren",
    PriceGrowthoverthepast1Years: "Preiswachstum im letzten Jahr",
    Profitability: "Rentabel? (Quartalsweise)",
    GrowingRevenue: "Wachstum des Umsatzes? (Quartalsweise)",
    GrowingNetProfit: "Wachstum des Nettogewinns? (Quartalsweise)",
    ProfitabilityAnnual: "Rentabel? (Jährlich)",
    GrowingRevenueAnnual: "Wachstum des Umsatzes? (Jährlich)",
    GrowingNetProfitAnnual: "Wachstum des Nettogewinns? (Jährlich)",
    FinancialMetrics: "Bewertungszusammenfassung",
    BalanceSheet: "Finanzkennzahlen",
    Cash: "Bargeld",
    Debt: "Schulden",
    CashDebtRatio: "Bargeld-Schulden-Verhältnis",
    DividendYield: "Dividendenrendite",
    PayoutRatio: "Ausschüttungsquote",
    FiveYearAvgDividendYield:
      "Durchschnittliche Dividendenrendite der letzten 5 Jahre",
    DividendGrowthRateoverthepast5Years:
      "Dividendenwachstumsrate der letzten 5 Jahre",
    QualityOfCompany: "Unternehmensqualität",
    Dividend: "Dividende",
    comparedToLastQuarter: "Verglichen mit dem letzten Quartal",
    comparedToLastYear: "Verglichen mit dem letzten Jahr",
    newTab: "Neuer Tab",
    compare: "Vergleichen",
    topCompanies: "Top-Unternehmen",
    watchlist: "Beobachtungsliste",
    rank: "Rang",
    companyName: "Firmenname",
    symbol: "Symbol",
    marketCap: "Marktkapitalisierung",
    price: "Preis",
    today: "Heute",
    volume: "Volumen",
    country: "Land",
    joinDiscord: "Discord beitreten",
    joinTelegram: "Telegram beitreten",
    contactUs: "Kontaktieren Sie uns",
    InTheLast365Days: "In den letzten 365 Tagen",
    LastUpdated: "Zuletzt aktualisiert",
    pages: "Seiten",

    titles: {
      Price: "Preis",
      MarketCap: "Marktkapitalisierung",
      Revenue: "Umsatz",
      RevenueVsNetIncome: "Umsatz vs. Nettoergebnis",
      NetIncome: "Nettoergebnis",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Gesamtausgaben",
      SharesIssued: "Ausstehende Aktien",
      FreeCashFlow: "Freier Cashflow",
      OperatingCashFlow: "Operativer Cashflow",
      Dividend: "Dividende",
      GrossMargin: "Bruttomarge",
      CashVsDebt: "Bargeld vs. Schulden",
    },
    description: {
      Price: "Historischer Aktienkurs des Unternehmens im Laufe der Zeit",
      MarketCap:
        "Der Gesamtwert der im Umlauf befindlichen Aktien eines Unternehmens im Laufe der Zeit",
      Revenue:
        "Der Gesamtbetrag des durch Geschäftstätigkeiten, Waren- und Dienstleistungsverkäufe generierten Geldes im Laufe der Zeit",
      RevenueVsNetIncome:
        "Ein Vergleich zwischen den Gesamteinnahmen und dem Nettogewinn eines Unternehmens im Laufe der Zeit, der die Nachhaltigkeit des Unternehmenswachstums anzeigt. Ein niedrigeres Verhältnis kann darauf hinweisen, dass das Unternehmen einen Wettbewerbsvorteil entwickelt und Marktanteile gewinnt",
      NetIncome:
        "Der Gewinn, den ein Unternehmen nach Abzug aller Ausgaben, Steuern und Kosten vom Gesamteinkommen im Laufe der Zeit erzielt",
      Ebitda:
        "Die Erträge eines Unternehmens vor Zinsen, Steuern, Abschreibungen und Amortisation im Laufe der Zeit. Es misst die Gesamtfinanzleistung und Rentabilität eines Unternehmens aus seinen Kerngeschäften",
      Eps: "Der Teil des Gewinns eines Unternehmens, der auf jede ausstehende Stammaktie entfällt. Er zeigt, wie viel Geld die Aktionäre für jede Aktie, die sie besitzen, erhalten würden",
      TotalExpenses:
        "Die Summe aller Kosten, die ein Unternehmen bei der Durchführung seines Geschäfts verursacht, einschließlich Betriebskosten, Zinsen, Steuern usw. Das Diagramm kann die Gesamtausgaben im Laufe der Zeit zeigen und auf Kostenentwicklung und Effizienz hinweisen",
      SharesIssued: "Die Gesamtzahl der ausgegebenen Aktien eines Unternehmens",
      FreeCashFlow:
        "Der Cashflow, den ein Unternehmen nach Berücksichtigung der Barausflüsse zur Unterstützung des Betriebs und zur Aufrechterhaltung von Kapitalanlagen generiert. Er stellt das Geld dar, das für Dividenden, Schuldentilgung oder Reinvestitionen verfügbar ist",
      OperatingCashFlow:
        "Der Cashflow aus den Geschäftstätigkeiten eines Unternehmens im Laufe der Zeit. Er zeigt, ob ein Unternehmen genügend positiven Cashflow generieren kann, um seinen Betrieb aufrechtzuerhalten und auszubauen",
      Dividend:
        "Ein Teil des Gewinns eines Unternehmens, der an die Aktionäre verteilt wird, in der Regel in Form von Barauszahlungen.",
      GrossMargin:
        "Der Prozentsatz des verbleibenden Umsatzes nach Abzug der Kosten der verkauften Waren (COGS). Er zeigt, wie effizient ein Unternehmen seine Produkte produziert und verkauft",
      CashVsDebt:
        "Ein Vergleichsdiagramm, das die Bargeldreserven eines Unternehmens im Vergleich zu seinen Gesamtschulden zeigt. Es hilft, die Finanzlage und Liquidität des Unternehmens zu beurteilen",
    },
    dataDescriptions: {
      MarketCap:
        "Der Gesamtwert der Aktien eines Unternehmens; er wird berechnet, indem der Aktienkurs mit der Gesamtzahl der Aktien multipliziert wird",
      PE: "Das Kurs-Gewinn-Verhältnis (KGV) misst, wie viel Anleger bereit sind, für jeden Dollar Gewinn eines Unternehmens zu zahlen; es wird berechnet, indem der Aktienkurs durch den Gewinn pro Aktie geteilt wird. Ein hohes KGV kann darauf hindeuten, dass Anleger ein hohes zukünftiges Wachstum erwarten oder die Aktie überbewertet sein könnte",
      PS: "Das Kurs-Umsatz-Verhältnis (KUV) misst, wie viel Anleger für jeden Dollar Umsatz des Unternehmens zahlen; es wird berechnet, indem der Aktienkurs durch den Umsatz pro Aktie geteilt wird. Ein hohes KUV kann darauf hindeuten, dass Anleger ein hohes zukünftiges Umsatzwachstum erwarten oder die Aktie überbewertet sein könnte",
      PB: "Das Kurs-Buchwert-Verhältnis (KBV) vergleicht den Marktwert eines Unternehmens mit seinem Buchwert (Vermögenswerte minus Verbindlichkeiten); es wird berechnet, indem der Aktienkurs durch den Buchwert pro Aktie geteilt wird. Ein hohes KBV kann auf ein starkes Vertrauen der Anleger in die zukünftige Leistung des Unternehmens hinweisen oder darauf, dass die Aktie überbewertet sein könnte, wenn das Unternehmen kein starkes Wachstum der Vermögenswerte aufweist",
      FCFYield:
        "Die Free-Cashflow-Rendite ist ein Maß dafür, wie viel Free-Cashflow ein Unternehmen im Verhältnis zu seiner Marktkapitalisierung generiert; sie wird berechnet, indem der Free-Cashflow durch die Marktkapitalisierung geteilt wird",
      PEGRatio:
        "Das Kurs-Gewinn-Wachstums-Verhältnis (PEG) ist eine Bewertungsmetrik, die das KGV eines Unternehmens in Bezug auf seine Gewinnwachstumsrate berücksichtigt; es hilft zu erkennen, ob eine Aktie über- oder unterbewertet ist. Ein PEG-Verhältnis von 1 deutet auf einen fairen Wert hin, unter 1 auf eine Unterbewertung im Verhältnis zur Wachstumsrate und über 1 auf eine Überbewertung im Verhältnis zur Wachstumsrate",
      OverallRisk:
        "Eine Einschätzung, wie riskant eine Investition ist, unter Berücksichtigung von Faktoren wie Volatilität, finanzieller Stabilität und Marktbedingungen; ein geringeres Risiko ist in der Regel besser, hängt jedoch von der Risikobereitschaft des Anlegers ab",
      week52HighPrice:
        "Der höchste Kurs, den eine Aktie im letzten Jahr erreicht hat; eine Aktie, die nahe ihrem 52-Wochen-Hoch notiert, kann auf eine starke jüngste Leistung hinweisen, könnte aber auch darauf hindeuten, dass sie überbewertet ist",
      ProfitabilityAnnual:
        "Ob das Unternehmen im letzten Jahr mehr Geld verdient hat, als es ausgegeben hat; Rentabilität ist im Allgemeinen ein gutes Zeichen für die finanzielle Gesundheit eines Unternehmens",
      GrowingRevenueAnnual:
        "Ob der Umsatz (Verkäufe) des Unternehmens im Vergleich zum Vorjahr gestiegen ist; ein stetiges Umsatzwachstum ist in der Regel ein positives Zeichen für die Expansion des Unternehmens",
      GrowingNetProfitAnnual:
        "Ob der Nettogewinn des Unternehmens (Einkommen nach Ausgaben) im Vergleich zum Vorjahr gestiegen ist; ein wachsender Nettogewinn ist ein starkes Indiz für eine Verbesserung der finanziellen Leistung",
      PriceGrowthoverthepast5Years:
        "Der prozentuale Anstieg des Aktienkurses in den letzten fünf Jahren; ein höherer Prozentsatz deutet auf eine bessere langfristige Leistung hin",
      PriceGrowthoverthepast1Years:
        "Der prozentuale Anstieg des Aktienkurses im letzten Jahr; starkes kurzfristiges Wachstum ist positiv, sollte jedoch im Kontext anderer Faktoren betrachtet werden",
      Cash: "Der Gesamtbetrag an liquiden Mitteln, die das Unternehmen sofort zur Verfügung hat; mehr Bargeld ist im Allgemeinen besser für die finanzielle Flexibilität",
      Debt: "Der Gesamtbetrag an Geld, den das Unternehmen Gläubigern schuldet; eine höhere Verschuldung kann riskant sein, wenn das Unternehmen nicht in der Lage ist, genügend Einkommen zu generieren, um sie zurückzuzahlen",
      CashDebtRatio:
        "Ein Maß für die Fähigkeit eines Unternehmens, seine Schulden mit den verfügbaren liquiden Mitteln zu begleichen; ein höheres Verhältnis deutet auf eine bessere finanzielle Gesundheit hin",
      Profitability:
        "Ob das Unternehmen im letzten Quartal mehr Geld verdient hat, als es ausgegeben hat; eine vierteljährliche Rentabilität zeigt, dass das Unternehmen kontinuierlich Einkommen generiert",
      GrowingRevenue:
        "Ob der Umsatz des Unternehmens im Vergleich zum Vorquartal gestiegen ist; kontinuierliches Umsatzwachstum ist ein Zeichen für anhaltenden Geschäftserfolg",
      GrowingNetProfit:
        "Ob der Nettogewinn des Unternehmens im Vergleich zum Vorquartal gestiegen ist; ein wachsender Nettogewinn im Quartalsvergleich deutet auf eine Verbesserung der finanziellen Leistung auf kurze Sicht hin",
      DividendYield:
        "Die jährliche Dividendenzahlung, die ein Unternehmen an seine Aktionäre ausschüttet, ausgedrückt als Prozentsatz des Aktienkurses; eine höhere Rendite kann auf mehr Einkommen aus Dividenden im Verhältnis zum Aktienkurs hinweisen",
      PayoutRatio:
        "Der Prozentsatz des Gewinns eines Unternehmens, der als Dividende an die Aktionäre ausgeschüttet wird; ein niedrigeres Verhältnis deutet darauf hin, dass das Unternehmen mehr Gewinne für Wachstum zurückhält, während ein sehr hohes Verhältnis darauf hindeuten könnte, dass die Dividende möglicherweise nicht nachhaltig ist",
      FiveYearAvgDividendYield:
        "Die durchschnittliche Dividendenrendite, die ein Unternehmen in den letzten fünf Jahren angeboten hat; sie hilft den Anlegern zu erkennen, wie konstant oder stabil die Dividendenzahlungen im Laufe der Zeit waren",
    },
    labels: {
      TotalCash: "Gesamtes Bargeld",
      TotalDebt: "Gesamtschulden",
      Revenue: "Umsatz",
      NetIncome: "Nettoergebnis",
    },
    Quarterly: "Quartalsweise",
    Annually: "Jährlich",
    FullScreen: "Vollbild",
    ExitFullScreen: "Vollbild beenden",
    homeMetaTitle: "StockPenguins | Kostenloser Globaler Aktien-Scanner",
    homeMetaDescription:
      "Entdecken Sie die ultimative kostenlose Aktien-Scanner-App für US-Aktien wie Apple, Tesla, Amazon und Microsoft. Greifen Sie auf detaillierte Kennzahlen wie Preis, Marktkapitalisierung, Umsatz, Nettogewinn, EBITDA, Freier Cashflow, EPS, Dividende, KGV und mehr zu. Analysieren und vergleichen Sie Aktien mühelos, um fundierte Anlageentscheidungen zu treffen. Jetzt suchen!",
    pageMetaTitle: "Aktienkennzahlen | KGV, Umsatz und Marktkapitalisierung",
    pageMetaDescription:
      "Entdecken Sie kostenlose Kennzahlen, einschließlich Preis, Marktkapitalisierung, Umsatz, Nettogewinn, EBITDA, Freier Cashflow, EPS, Dividende, KGV und mehr. Bleiben Sie über die finanzielle Leistung von Apple informiert. Jetzt erkunden!",
  },
  [LANGUAGES[5].value]: {
    // Dutch
    welcome: "Welkom",
    SearchBarPlaceholder: "Wat wordt jouw volgende tienvoudige winnaar?",
    Search: "Zoeken",
    Industry: "Industrie",
    Sector: "Sector",
    MarketCap: "Marktkapitalisatie",
    PE: "K/W",
    PS: "P/S",
    PB: "P/B",
    FCFYield: "FCF-rendement",
    PEGRatio: "PEG-ratio",
    OverallRisk: "Algemeen Risico",
    week52HighPrice: "52-Week Hoogste Prijs",
    PriceGrowthoverthepast5Years: "Prijsstijging over de afgelopen 5 jaar",
    PriceGrowthoverthepast1Years: "Prijsstijging over het afgelopen jaar",
    Profitability: "Winstgevend? (Kwartaal)",
    GrowingRevenue: "Groeiende omzet? (Kwartaal)",
    GrowingNetProfit: "Groeiende nettowinst? (Kwartaal)",
    ProfitabilityAnnual: "Winstgevend? (Jaarlijks)",
    GrowingRevenueAnnual: "Groeiende omzet? (Jaarlijks)",
    GrowingNetProfitAnnual: "Groeiende nettowinst? (Jaarlijks)",
    FinancialMetrics: "Waarderingsoverzicht",
    BalanceSheet: "Financiële kengetallen",
    Cash: "Kasgeld",
    Debt: "Schuld",
    CashDebtRatio: "Kasgeld/Schuld-verhouding",
    DividendYield: "Dividendrendement",
    PayoutRatio: "Uitbetalingspercentage",
    FiveYearAvgDividendYield: "Gemiddeld dividendrendement over 5 jaar",
    DividendGrowthRateoverthepast5Years:
      "Dividendgroeipercentage over de afgelopen 5 jaar",
    QualityOfCompany: "Kwaliteit van het Bedrijf",
    Dividend: "Dividend",
    comparedToLastQuarter: "Vergeleken met vorig kwartaal",
    comparedToLastYear: "Vergeleken met vorig jaar",
    newTab: "Nieuw tabblad",
    compare: "Vergelijken",
    topCompanies: "Topbedrijven",
    watchlist: "Volglijst",
    rank: "Rang",
    companyName: "Bedrijfsnaam",
    symbol: "Symbool",
    marketCap: "Marktkapitalisatie",
    price: "Prijs",
    today: "Vandaag",
    volume: "Volume",
    country: "Land",
    joinDiscord: "Word lid van Discord",
    joinTelegram: "Word lid van Telegram",
    contactUs: "Neem contact op",
    InTheLast365Days: "In de laatste 365 dagen",
    LastUpdated: "Laatst bijgewerkt",
    pages: "Pagina's",

    titles: {
      Price: "Prijs",
      MarketCap: "Marktkapitalisatie",
      Revenue: "Omzet",
      RevenueVsNetIncome: "Omzet vs. Netto-inkomen",
      NetIncome: "Netto-inkomen",
      Ebitda: "EBITDA",
      Eps: "WPA",
      TotalExpenses: "Totale Uitgaven",
      SharesIssued: "Uitstaande Aandelen",
      FreeCashFlow: "Vrije Kasstroom",
      OperatingCashFlow: "Operationele Kasstroom",
      Dividend: "Dividend",
      GrossMargin: "Brutomarge",
      CashVsDebt: "Kasgeld vs. Schuld",
    },
    description: {
      Price: "Historische aandelenkoers van het bedrijf in de loop der tijd",
      MarketCap:
        "De totale waarde van de uitstaande aandelen van een bedrijf in de loop der tijd",
      Revenue:
        "Het totale bedrag aan geld gegenereerd door bedrijfsactiviteiten, verkoop van goederen en diensten in de loop der tijd",
      RevenueVsNetIncome:
        "Een vergelijking tussen de totale omzet en de nettowinst van het bedrijf in de loop der tijd, die aangeeft hoe duurzaam de groei van het bedrijf is. Een lagere verhouding kan erop wijzen dat het bedrijf een concurrentievoordeel ontwikkelt en marktaandeel wint",
      NetIncome:
        "De winst die een bedrijf maakt na aftrek van alle kosten, belastingen en kosten van de totale omzet in de loop der tijd",
      Ebitda:
        "De winst van een bedrijf voor rente, belastingen, afschrijvingen en amortisatie in de loop der tijd. Het meet de algemene financiële prestaties en winstgevendheid van een bedrijf uit de kernactiviteiten",
      Eps: "Het deel van de winst van een bedrijf dat wordt toegewezen aan elk uitstaand gewoon aandeel. Het geeft aan hoeveel geld aandeelhouders zouden ontvangen voor elk aandeel dat ze bezitten",
      TotalExpenses:
        "Het totaal van alle kosten die een bedrijf maakt bij het uitvoeren van zijn activiteiten, inclusief operationele kosten, rente, belastingen, enz. De grafiek kan de totale uitgaven in de loop der tijd weergeven, wat trends in kosten en efficiëntie aangeeft",
      SharesIssued: "Het totale aantal uitgegeven aandelen van een bedrijf",
      FreeCashFlow:
        "De kasstroom die een bedrijf genereert na aftrek van kasuitstromen ter ondersteuning van de activiteiten en het onderhouden van kapitaalactiva. Het vertegenwoordigt het geld dat beschikbaar is voor dividenden, schuldenaflossing of herinvestering",
      OperatingCashFlow:
        "De kasstroom gegenereerd uit de bedrijfsactiviteiten van een bedrijf in de loop der tijd. Het laat zien of een bedrijf voldoende positieve kasstroom kan genereren om zijn activiteiten te behouden en uit te breiden",
      Dividend:
        "Een deel van de winst van een bedrijf dat wordt uitgekeerd aan aandeelhouders, meestal in de vorm van contante betalingen.",
      GrossMargin:
        "Het percentage van de omzet dat overblijft na aftrek van de kosten van verkochte goederen (COGS). Het laat zien hoe efficiënt een bedrijf zijn producten produceert en verkoopt",
      CashVsDebt:
        "Een vergelijkende grafiek die de kasreserves van het bedrijf toont in vergelijking met de totale schuld. Het helpt bij het beoordelen van de financiële gezondheid en liquiditeit van het bedrijf",
    },
    dataDescriptions: {
      MarketCap:
        "De totale waarde van de aandelen van een bedrijf; het wordt berekend door de aandelenkoers te vermenigvuldigen met het totale aantal aandelen",
      PE: "De koers-winstverhouding (K/W) meet hoeveel beleggers bereid zijn te betalen voor elke dollar winst van een bedrijf; het wordt berekend door de aandelenkoers te delen door de winst per aandeel. Een hoge K/W-verhouding kan erop wijzen dat beleggers een hoog toekomstig groeipercentage verwachten of dat het aandeel overgewaardeerd is",
      PS: "De koers-omzetverhouding (K/O) meet hoeveel beleggers betalen voor elke dollar van de omzet van het bedrijf; het wordt berekend door de aandelenkoers te delen door de omzet per aandeel. Een hoge K/O-verhouding kan erop wijzen dat beleggers een hoge toekomstige omzetgroei verwachten of dat het aandeel overgewaardeerd is",
      PB: "De koers-boekwaarde verhouding (K/B) is een verhouding die de marktwaarde van een bedrijf vergelijkt met de boekwaarde (activa minus passiva); het wordt berekend door de aandelenkoers te delen door de boekwaarde per aandeel. Een hoge K/B-verhouding kan wijzen op sterk vertrouwen van beleggers in de toekomstige prestaties van het bedrijf of dat het aandeel overgewaardeerd kan zijn als het bedrijf geen sterke activa-groei heeft",
      FCFYield:
        "De vrije kasstroomrendement is een maatstaf voor hoeveel vrije kasstroom een bedrijf genereert ten opzichte van zijn marktkapitalisatie; het wordt berekend door de vrije kasstroom te delen door de marktkapitalisatie",
      PEGRatio:
        "De koers/winst tot groei-verhouding (PEG) is een waarderingsmaatstaf die de K/W-verhouding van een bedrijf in verhouding tot zijn winstgroeipercentage beschouwt; het helpt te bepalen of een aandeel over- of ondergewaardeerd is. Een PEG-verhouding van 1 duidt op een faire waarde, onder 1 duidt op onderwaardering ten opzichte van de groeisnelheid, en boven 1 duidt op overwaardering ten opzichte van de groeisnelheid",
      OverallRisk:
        "Een beoordeling van hoe risicovol een investering is, rekening houdend met factoren zoals volatiliteit, financiële stabiliteit en marktomstandigheden; een lager risico is meestal beter, maar hangt af van de risicotolerantie van de belegger",
      week52HighPrice:
        "De hoogste koers die een aandeel in het afgelopen jaar heeft bereikt; een aandeel dat in de buurt van zijn 52-weeks hoogtepunt handelt, kan wijzen op een sterke recente prestatie, maar het kan ook suggereren dat het overgewaardeerd is",
      ProfitabilityAnnual:
        "Of het bedrijf het afgelopen jaar meer geld heeft verdiend dan het heeft uitgegeven; winstgevendheid is over het algemeen een goed teken van de financiële gezondheid van een bedrijf",
      GrowingRevenueAnnual:
        "Of de omzet van het bedrijf is gestegen ten opzichte van het voorgaande jaar; consistente omzetgroei is meestal een positief teken van bedrijfsuitbreiding",
      GrowingNetProfitAnnual:
        "Of de nettowinst van het bedrijf is gestegen ten opzichte van het voorgaande jaar; een groeiende nettowinst is een sterke indicator van verbetering van de financiële prestaties",
      PriceGrowthoverthepast5Years:
        "Het percentage stijging van de aandelenkoers in de afgelopen vijf jaar; een hoger percentage wijst op betere prestaties op de lange termijn",
      PriceGrowthoverthepast1Years:
        "Het percentage stijging van de aandelenkoers in het afgelopen jaar; sterke kortetermijngroei is positief, maar moet in context met andere factoren worden beschouwd",
      Cash: "Het totale bedrag aan liquide middelen dat het bedrijf direct beschikbaar heeft; meer contant geld is over het algemeen beter voor financiële flexibiliteit",
      Debt: "Het totale bedrag aan geld dat het bedrijf verschuldigd is aan schuldeisers; hogere schulden kunnen risicovol zijn als het bedrijf niet genoeg inkomsten kan genereren om het af te betalen",
      CashDebtRatio:
        "Een maatstaf voor het vermogen van een bedrijf om zijn schulden af te betalen met het beschikbare contante geld; een hogere verhouding wijst op een betere financiële gezondheid",
      Profitability:
        "Of het bedrijf in het meest recente kwartaal meer geld heeft verdiend dan het heeft uitgegeven; een kwartaalwinstgevendheid toont aan dat het bedrijf consequent inkomsten genereert",
      GrowingRevenue:
        "Of de omzet van het bedrijf is gestegen ten opzichte van het voorgaande kwartaal; voortdurende omzetgroei is een teken van aanhoudend zakelijk succes",
      GrowingNetProfit:
        "Of de nettowinst van het bedrijf is gestegen ten opzichte van het voorgaande kwartaal; een groeiende nettowinst op kwartaalbasis wijst op een verbetering van de financiële prestaties op korte termijn",
      DividendYield:
        "De jaarlijkse dividenduitkering die een bedrijf aan zijn aandeelhouders geeft, uitgedrukt als een percentage van de aandelenkoers; een hoger rendement kan wijzen op meer inkomsten uit dividenden in verhouding tot de aandelenkoers",
      PayoutRatio:
        "Het percentage van de winst van een bedrijf dat als dividend aan de aandeelhouders wordt uitgekeerd; een lagere verhouding suggereert dat het bedrijf meer winst overhoudt voor groei, terwijl een zeer hoge verhouding erop kan wijzen dat het dividend mogelijk niet houdbaar is",
      FiveYearAvgDividendYield:
        "Het gemiddelde dividendrendement dat een bedrijf in de afgelopen vijf jaar heeft aangeboden; het helpt beleggers te zien hoe consistent of stabiel de dividenduitkeringen in de loop van de tijd zijn geweest",
    },
    labels: {
      TotalCash: "Totale Kasgeld",
      TotalDebt: "Totale Schuld",
      Revenue: "Omzet",
      NetIncome: "Netto-inkomen",
    },
    Quarterly: "Kwartaal",
    Annually: "Jaarlijks",
    FullScreen: "Volledig scherm",
    ExitFullScreen: "Volledig scherm verlaten",
    homeMetaTitle: "StockPenguins | Gratis Wereldwijde Aandelen Scanner",
    homeMetaDescription:
      "Ontdek de ultieme gratis aandelen scanner app voor Amerikaanse aandelen zoals Apple, Tesla, Amazon en Microsoft. Toegang tot gedetailleerde metrics zoals Prijs, Marktkapitalisatie, Inkomsten, Nettowinst, EBITDA, Vrije Kasstroom, Winst per Aandeel, Dividend, K/W Ratio en meer. Analyseer en vergelijk aandelen moeiteloos om weloverwogen investeringsbeslissingen te nemen. Zoek nu!",
    pageMetaTitle: "Aandelen Metrics | K/W, Inkomsten & Marktkapitalisatie",
    pageMetaDescription:
      "Ontdek gratis metrics, inclusief Prijs, Marktkapitalisatie, Inkomsten, Nettowinst, EBITDA, Vrije Kasstroom, Winst per Aandeel, Dividend, K/W Ratio en meer. Blijf op de hoogte van de financiële prestaties van Apple. Verken nu!",
  },
  [LANGUAGES[6].value]: {
    // Japanese
    welcome: "ようこそ",
    SearchBarPlaceholder: "次のテンバガーは何になる？",
    Search: "検索",
    Industry: "産業",
    Sector: "セクター",
    MarketCap: "時価総額",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "FCF利回り",
    PEGRatio: "PEGレシオ",
    OverallRisk: "全体的なリスク",
    week52HighPrice: "52週間高値",
    PriceGrowthoverthepast5Years: "過去5年間の株価成長率",
    PriceGrowthoverthepast1Years: "過去1年間の株価成長率",
    Profitability: "収益性（四半期）",
    GrowingRevenue: "売上高成長（四半期）",
    GrowingNetProfit: "純利益成長（四半期）",
    ProfitabilityAnnual: "収益性（年次）",
    GrowingRevenueAnnual: "売上高成長（年次）",
    GrowingNetProfitAnnual: "純利益成長（年次）",
    BalanceSheet: "財務メトリクス",
    Cash: "現金",
    Debt: "債務",
    CashDebtRatio: "現金・債務比率",
    DividendYield: "配当利回り",
    PayoutRatio: "配当性向",
    FiveYearAvgDividendYield: "過去5年間の平均配当利回り",
    DividendGrowthRateoverthepast5Years: "過去5年間の配当成長率",
    FinancialMetrics: "財務指標",
    QualityOfCompany: "企業の質",
    Dividend: "配当",
    comparedToLastQuarter: "前四半期と比較して",
    comparedToLastYear: "前年と比較して",
    newTab: "新しいタブ",
    compare: "比較",
    topCompanies: "トップ企業",
    watchlist: "ウォッチリスト",
    rank: "ランク",
    companyName: "会社名",
    symbol: "シンボル",
    marketCap: "時価総額",
    price: "価格",
    today: "今日",
    volume: "出来高",
    country: "国",
    joinDiscord: "Discordに参加",
    joinTelegram: "Telegramに参加",
    contactUs: "お問い合わせ",
    InTheLast365Days: "過去365日間で",
    LastUpdated: "最終更新",
    pages: "ページ",

    titles: {
      Price: "価格",
      MarketCap: "時価総額",
      Revenue: "収益",
      RevenueVsNetIncome: "収益対純利益",
      NetIncome: "純利益",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "総費用",
      SharesIssued: "発行済株式数",
      FreeCashFlow: "フリーキャッシュフロー",
      OperatingCashFlow: "営業キャッシュフロー",
      Dividend: "配当",
      GrossMargin: "粗利益率",
      CashVsDebt: "現金対債務",
    },
    description: {
      Price: "企業の株価の推移",
      MarketCap: "企業の発行済株式の総価値の推移",
      Revenue: "企業活動、商品・サービスの販売から得られる総収入の推移",
      RevenueVsNetIncome:
        "企業の総収入と純利益の比較で、企業の成長の持続可能性を示します。比率が低いほど、企業が競争優位を築き、市場シェアを拡大している可能性があります",
      NetIncome:
        "総収入からすべての費用、税金、コストを差し引いた後の企業の利益の推移",
      Ebitda:
        "利息、税金、減価償却費および償却費前の企業の収益の推移。企業の主要な業務からの全体的な財務業績と収益性を測定します",
      Eps: "発行済の普通株式1株あたりに配分される企業の利益の部分。株主が所有する各株式に対してどれだけのお金を受け取るかを示します",
      TotalExpenses:
        "企業が事業を行う上で発生するすべての費用の合計。営業費用、利息、税金などが含まれます。グラフは総費用の推移を示し、費用の傾向と効率を示します",
      SharesIssued: "企業の発行株式総数",
      FreeCashFlow:
        "運転資金と資本資産の維持に必要な現金流出を差し引いた後の企業が生成する現金。配当、債務返済、または再投資に利用可能なお金を表します",
      OperatingCashFlow:
        "企業の事業活動から生じるキャッシュフローの推移。企業が事業を維持・拡大するために十分な正のキャッシュフローを生成できるかどうかを示します",
      Dividend: "企業の利益の一部が株主に分配され、通常は現金で支払われます",
      GrossMargin:
        "売上総利益率。売上総利益（COGS）を差し引いた後の収益の割合。企業が製品を効率的に生産・販売しているかを示します",
      CashVsDebt:
        "企業の現金準備金と総債務の比較グラフ。企業の財務状況と流動性を評価するのに役立ちます",
    },
    dataDescriptions: {
      MarketCap:
        "会社の株式の総価値で、株価に発行済株式数を掛けることで計算されます",
      PE: "株価収益率 (P/E) は、投資家が会社の利益の1ドルに対して支払う意欲がある額を測定します。株価を1株あたりの利益で割って計算します。高いP/E比率は、投資家が高い将来の成長率を期待しているか、または株が過大評価されている可能性があることを示す場合があります",
      PS: "株価売上高比率 (P/S) は、投資家が会社の売上高の1ドルに対して支払う額を測定します。株価を1株あたりの売上高で割って計算します。高いP/S比率は、投資家が高い将来の売上成長を期待しているか、または株が過大評価されている可能性があることを示す場合があります",
      PB: "株価純資産比率 (P/B) は、会社の市場価値を帳簿価値 (資産から負債を引いた額) と比較する比率です。株価を1株あたりの帳簿価値で割って計算します。高いP/B比率は、会社の将来のパフォーマンスに対する強い投資家の信頼を示すか、会社が強い資産成長を持っていない場合は株が過大評価されている可能性があります",
      FCFYield:
        "フリーキャッシュフロー利回りは、会社が生成するフリーキャッシュフローを市場価値に対して測定する指標です。フリーキャッシュフローを市場価値で割って計算します",
      PEGRatio:
        "株価収益成長比率 (PEG) は、会社のP/E比率を利益成長率と比較する評価指標です。これにより、株が過大評価または過小評価されているかを確認できます。PEG比率が1の場合は公正価値を示し、1未満は成長率に対して過小評価、1を超える場合は成長率に対して過大評価を示します",
      OverallRisk:
        "投資がどれだけリスクがあるかの評価で、ボラティリティ、財務の安定性、市場の状況などの要素を考慮します。リスクが低いほど通常は良いですが、投資家のリスク許容度によります",
      week52HighPrice:
        "過去1年間における株の最高価格で、52週間の高値に近い株は強い最近のパフォーマンスを示す場合がありますが、過大評価されている可能性もあります",
      ProfitabilityAnnual:
        "会社が過去1年間に費やした額よりも多くの利益を上げたかどうか。利益率は一般的に会社の財務健全性の良い兆候です",
      GrowingRevenueAnnual:
        "会社の売上高が前年度と比べて増加したかどうか。継続的な売上高の成長は通常、ビジネスの拡張を示すポジティブなサインです",
      GrowingNetProfitAnnual:
        "会社の純利益 (費用後の収入) が前年度と比べて増加したかどうか。増加する純利益は、財務パフォーマンスの改善を示す強い指標です",
      PriceGrowthoverthepast5Years:
        "過去5年間の株価のパーセンテージ増加で、より高いパーセンテージは長期的なパフォーマンスの向上を示します",
      PriceGrowthoverthepast1Years:
        "過去1年間の株価のパーセンテージ増加で、強い短期成長はポジティブですが、他の要素とともに考慮する必要があります",
      Cash: "会社が即座に使用できる流動資金の総額で、より多くの現金は一般的に財務の柔軟性を向上させます",
      Debt: "会社が貸し手に対して負っている総額で、高い負債は会社が十分な収益を生み出せない場合にリスクが伴います",
      CashDebtRatio:
        "会社が手元の現金で負債を返済する能力を測定する指標で、高い比率はより良い財務健全性を示します",
      Profitability:
        "会社が最も最近の四半期に費やした額よりも多くの利益を上げたかどうか。四半期ごとの利益は、会社が一貫して収益を生み出していることを示します",
      GrowingRevenue:
        "会社の売上高が前の四半期と比べて増加したかどうか。継続的な売上高の増加は、ビジネスの成功を示すサインです",
      GrowingNetProfit:
        "会社の純利益が前の四半期と比べて増加したかどうか。四半期ごとの純利益の増加は、短期的な財務パフォーマンスの改善を示します",
      DividendYield:
        "会社が株主に支払う年間配当で、株価のパーセンテージとして表されます。高い利回りは、株価に対してより多くの配当収入を示す場合があります",
      PayoutRatio:
        "会社の利益の中で株主に支払われる配当の割合で、低い比率は会社がより多くの利益を成長のために留保していることを示し、非常に高い比率は配当が持続不可能である可能性を示します",
      FiveYearAvgDividendYield:
        "過去5年間にわたる会社の平均配当利回りで、投資家が配当支払いの一貫性や安定性を確認するのに役立ちます",
    },
    labels: {
      TotalCash: "総現金",
      TotalDebt: "総債務",
      Revenue: "収益",
      RevenueVsNetIncome: "収益対純利益",
      NetIncome: "純利益",
    },
    Quarterly: "四半期ごと",
    Annually: "年次",
    FullScreen: "フルスクリーン",
    ExitFullScreen: "フルスクリーンを終了",
    homeMetaTitle: "StockPenguins | 無料のグローバル株式スキャナー",
    homeMetaDescription:
      "Apple、Tesla、Amazon、Microsoftなどの米国株のための究極の無料株式スキャナーアプリを発見しましょう。価格、市場価値、収益、純利益、EBITDA、フリーキャッシュフロー、EPS、配当、P/Eレシオなどの詳細な指標にアクセスできます。株式を簡単に分析および比較して、情報に基づいた投資判断を下しましょう。今すぐ検索！",
    pageMetaTitle: "株式指標 | P/E、収益、市場価値",
    pageMetaDescription:
      "価格、市場価値、収益、純利益、EBITDA、フリーキャッシュフロー、EPS、配当、P/Eレシオなど、無料の指標を発見しましょう。Appleの財務状況についての情報を把握しましょう。今すぐ探索！",
  },
  [LANGUAGES[7].value]: {
    // Italian
    welcome: "Benvenuto",
    SearchBarPlaceholder: "Quale sarà il tuo prossimo 10 bagger?",
    Search: "Cerca",
    Industry: "Industria",
    Sector: "Settore",
    MarketCap: "Capitalizzazione di Mercato",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Rendimento del FCF",
    PEGRatio: "Rapporto PEG",
    OverallRisk: "Rischio Complessivo",
    week52HighPrice: "Prezzo Massimo delle 52 Settimane",
    PriceGrowthoverthepast5Years: "Crescita del Prezzo negli Ultimi 5 Anni",
    PriceGrowthoverthepast1Years: "Crescita del Prezzo nell'Ultimo Anno",
    Profitability: "Redditività? (Trimestrale)",
    GrowingRevenue: "Crescita dei ricavi? (Trimestrale)",
    GrowingNetProfit: "Crescita del profitto netto? (Trimestrale)",
    ProfitabilityAnnual: "Redditività? (Annuale)",
    GrowingRevenueAnnual: "Crescita dei ricavi? (Annuale)",
    GrowingNetProfitAnnual: "Crescita del profitto netto? (Annuale)",
    FinancialMetrics: "Sintesi della valutazione",
    BalanceSheet: "Indicatori finanziari",
    Cash: "Cassa",
    Debt: "Debito",
    CashDebtRatio: "Rapporto Cassa/Debito",
    DividendYield: "Rendimento del Dividendo",
    PayoutRatio: "Rapporto di Pagamento",
    FiveYearAvgDividendYield:
      "Rendimento Medio del Dividendo negli Ultimi 5 Anni",
    DividendGrowthRateoverthepast5Years:
      "Tasso di Crescita del Dividendo negli Ultimi 5 Anni",
    QualityOfCompany: "Qualità dell'Azienda",
    Dividend: "Dividendo",
    comparedToLastQuarter: "Rispetto all'ultimo trimestre",
    comparedToLastYear: "Rispetto all'anno scorso",
    newTab: "Nuova scheda",
    compare: "Confronta",
    topCompanies: "Aziende migliori",
    watchlist: "Lista di controllo",
    rank: "Classifica",
    companyName: "Nome dell'azienda",
    symbol: "Simbolo",
    marketCap: "Capitalizzazione di mercato",
    price: "Prezzo",
    today: "Oggi",
    volume: "Volume",
    country: "Paese",
    joinDiscord: "Unisciti a Discord",
    joinTelegram: "Unisciti a Telegram",
    contactUs: "Contattaci",
    InTheLast365Days: "Negli ultimi 365 giorni",
    LastUpdated: "Ultimo aggiornamento",
    pages: "Pagine",

    titles: {
      Price: "Prezzo",
      MarketCap: "Capitalizzazione di Mercato",
      Revenue: "Entrate",
      RevenueVsNetIncome: "Entrate vs. Reddito Netto",
      NetIncome: "Reddito Netto",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Spese Totali",
      SharesIssued: "Azioni in circolazione",
      FreeCashFlow: "Flusso di Cassa Libero",
      OperatingCashFlow: "Flusso di Cassa Operativo",
      Dividend: "Dividendo",
      GrossMargin: "Margine Lordo",
      CashVsDebt: "Cassa vs. Debito",
    },
    description: {
      Price: "Prezzo storico delle azioni dell'azienda nel tempo",
      MarketCap:
        "Il valore totale delle azioni in circolazione di un'azienda nel tempo",
      Revenue:
        "L'importo totale di denaro generato dalle attività aziendali, dalla vendita di beni e servizi nel tempo",
      RevenueVsNetIncome:
        "Un confronto tra il totale delle entrate e il reddito netto dell'azienda nel tempo, che indica la sostenibilità della crescita dell'azienda. Un rapporto più basso può indicare che l'azienda sta sviluppando un vantaggio competitivo e guadagnando quote di mercato",
      NetIncome:
        "Il profitto che un'azienda realizza dopo aver detratto tutte le spese, le tasse e i costi dal totale delle entrate nel tempo",
      Ebitda:
        "Gli utili di un'azienda prima degli interessi, delle tasse, degli ammortamenti e delle svalutazioni nel tempo. Misura la performance finanziaria complessiva e la redditività di un'azienda dalle operazioni principali",
      Eps: "La parte degli utili di un'azienda attribuita a ciascuna azione in circolazione. Indica quanti soldi riceverebbero gli azionisti per ciascuna azione che possiedono",
      TotalExpenses:
        "La somma di tutti i costi sostenuti da un'azienda nello svolgimento della propria attività, comprese le spese operative, gli interessi, le tasse, ecc. Il grafico può mostrare le spese totali nel tempo, indicando tendenze e efficienza dei costi",
      SharesIssued: "Il numero totale di azioni emesse da un'azienda",
      FreeCashFlow:
        "Il denaro generato da un'azienda dopo aver considerato le uscite di cassa per supportare le operazioni e mantenere i beni di capitale. Rappresenta il denaro disponibile per i dividendi, il rimborso del debito o il reinvestimento",
      OperatingCashFlow:
        "Il flusso di cassa generato dalle operazioni aziendali di un'azienda nel tempo. Mostra se un'azienda può generare un flusso di cassa positivo sufficiente per mantenere e far crescere le proprie operazioni",
      Dividend:
        "Una parte degli utili di un'azienda distribuita agli azionisti, solitamente sotto forma di pagamenti in contanti.",
      GrossMargin:
        "La percentuale delle entrate rimanenti dopo aver sottratto il costo delle merci vendute (COGS). Mostra quanto efficacemente un'azienda produce e vende i propri prodotti",
      CashVsDebt:
        "Un grafico comparativo che mostra le riserve di cassa dell'azienda rispetto al debito totale. Aiuta a valutare la salute finanziaria e la liquidità dell'azienda",
    },
    dataDescriptions: {
      MarketCap:
        "Il valore totale delle azioni di una società; calcolato moltiplicando il prezzo delle azioni per il numero totale di azioni",
      PE: "Il rapporto Prezzo / Utili (P/E) misura quanto gli investitori sono disposti a pagare per ogni dollaro di utili della società; calcolato dividendo il prezzo delle azioni per l'utile per azione. Un alto rapporto P/E può indicare che gli investitori si aspettano un alto tasso di crescita futura o che le azioni possono essere sopravvalutate",
      PS: "Il rapporto Prezzo / Vendite (P/S) misura quanto gli investitori pagano per ogni dollaro delle vendite della società; calcolato dividendo il prezzo delle azioni per le vendite per azione. Un alto rapporto P/S può indicare che gli investitori si aspettano una forte crescita futura delle vendite o che le azioni possono essere sopravvalutate",
      PB: "Il rapporto Prezzo / Valore Contabile (P/B) è un rapporto che confronta il valore di mercato della società con il suo valore contabile (attività meno passività); calcolato dividendo il prezzo delle azioni per il valore contabile per azione. Un alto rapporto P/B può indicare una forte fiducia degli investitori nella performance futura della società o che le azioni potrebbero essere sopravvalutate se la società non ha una forte crescita degli attivi",
      FCFYield:
        "Il rendimento del flusso di cassa libero misura quanto flusso di cassa libero una società genera rispetto alla sua capitalizzazione di mercato; calcolato dividendo il flusso di cassa libero per la capitalizzazione di mercato",
      PEGRatio:
        "Il rapporto Prezzo / Utili rispetto alla Crescita (PEG) considera il rapporto P/E di una società in relazione al tasso di crescita degli utili; aiuta a vedere se un'azione è sopravvalutata o sottovalutata. Un rapporto PEG di 1 suggerisce un valore equo, inferiore a 1 suggerisce sottovalutazione rispetto al tasso di crescita, e superiore a 1 suggerisce sopravvalutazione rispetto al tasso di crescita",
      OverallRisk:
        "Una valutazione di quanto è rischiosa un'investimento, considerando fattori come volatilità, stabilità finanziaria e condizioni di mercato; un rischio più basso è solitamente migliore, ma dipende dalla tolleranza al rischio dell'investitore",
      week52HighPrice:
        "Il prezzo più alto che un'azione ha raggiunto nell'ultimo anno; un'azione scambiata vicino al suo massimo di 52 settimane può indicare una forte performance recente, ma potrebbe anche suggerire che è sopravvalutata",
      ProfitabilityAnnual:
        "Se la società ha guadagnato più di quanto ha speso nell'ultimo anno; la redditività è generalmente un buon segno della salute finanziaria di una società",
      GrowingRevenueAnnual:
        "Se i ricavi (vendite) della società sono aumentati rispetto all'anno precedente; una crescita dei ricavi coerente è generalmente un segno positivo di espansione aziendale",
      GrowingNetProfitAnnual:
        "Se il profitto netto (entrate dopo le spese) della società è aumentato rispetto all'anno precedente; un aumento del profitto netto è un forte indicatore di miglioramento delle performance finanziarie",
      PriceGrowthoverthepast5Years:
        "L'aumento percentuale del prezzo di un'azione negli ultimi cinque anni; una percentuale più alta indica una migliore performance a lungo termine",
      PriceGrowthoverthepast1Years:
        "L'aumento percentuale del prezzo di un'azione nell'ultimo anno; una forte crescita a breve termine è positiva ma dovrebbe essere considerata nel contesto di altri fattori",
      Cash: "L'ammontare totale di denaro liquido che la società ha disponibile per l'uso immediato; maggiore liquidità è generalmente migliore per la flessibilità finanziaria",
      Debt: "L'ammontare totale di denaro che la società deve ai creditori; un debito più elevato può essere rischioso se la società non riesce a generare abbastanza reddito per estinguerlo",
      CashDebtRatio:
        "Una misura della capacità di una società di estinguere il debito con il denaro disponibile; un rapporto più alto indica una migliore salute finanziaria",
      Profitability:
        "Se la società ha guadagnato più di quanto ha speso nell'ultimo trimestre; essere redditizi su base trimestrale mostra che la società sta generando costantemente reddito",
      GrowingRevenue:
        "Se i ricavi della società sono aumentati rispetto al trimestre precedente; la crescita continua dei ricavi è un segno di successo aziendale in corso",
      GrowingNetProfit:
        "Se il profitto netto della società è aumentato rispetto al trimestre precedente; un profitto netto crescente trimestralmente indica un miglioramento delle performance finanziarie a breve termine",
      DividendYield:
        "Il pagamento annuale dei dividendi che una società offre ai suoi azionisti, espresso come percentuale del prezzo delle azioni; un rendimento più alto può indicare un maggior reddito dai dividendi rispetto al prezzo delle azioni",
      PayoutRatio:
        "La percentuale degli utili di una società distribuita come dividendi agli azionisti; un rapporto più basso suggerisce che la società sta trattenendo più utili per la crescita, mentre un rapporto molto alto potrebbe indicare che il dividendo potrebbe essere insostenibile",
      FiveYearAvgDividendYield:
        "Il rendimento medio dei dividendi che una società ha offerto negli ultimi cinque anni; aiuta gli investitori a vedere quanto siano consistenti o stabili stati i pagamenti dei dividendi nel tempo",
    },
    labels: {
      TotalCash: "Cassa Totale",
      TotalDebt: "Debito Totale",
      Revenue: "Entrate",
      RevenueVsNetIncome: "Entrate vs. Reddito Netto",
      NetIncome: "Reddito Netto",
    },
    Quarterly: "Trimestrale",
    Annually: "Annualmente",
    FullScreen: "Schermo intero",
    ExitFullScreen: "Esci dallo schermo intero",
    homeMetaTitle: "StockPenguins | Scanner di Azioni Globali Gratuito",
    homeMetaDescription:
      "Scopri l'applicazione definitiva per il monitoraggio gratuito delle azioni per le azioni statunitensi come Apple, Tesla, Amazon e Microsoft. Accedi a metriche dettagliate come Prezzo, Capitalizzazione di Mercato, Ricavi, Utile Netto, EBITDA, Flusso di Cassa Libero, EPS, Dividendo, Rapporto P/E e altro ancora. Analizza e confronta le azioni senza sforzo per prendere decisioni di investimento informate. Cerca ora!",
    pageMetaTitle:
      "Metriche Azionarie | P/E, Ricavi e Capitalizzazione di Mercato",
    pageMetaDescription:
      "Scopri metriche gratuite, tra cui Prezzo, Capitalizzazione di Mercato, Ricavi, Utile Netto, EBITDA, Flusso di Cassa Libero, EPS, Dividendo, Rapporto P/E e altro ancora. Rimani informato sulle prestazioni finanziarie di Apple. Esplora ora!",
  },
  [LANGUAGES[8].value]: {
    // Korean
    welcome: "환영합니다",
    SearchBarPlaceholder: "다음 10배 주식은 무엇입니까?",
    Search: "검색",
    Industry: "산업",
    Sector: "부문",
    MarketCap: "시가총액",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "FCF 수익률",
    PEGRatio: "PEG 비율",
    OverallRisk: "전반적 위험",
    week52HighPrice: "52주 최고가",
    PriceGrowthoverthepast5Years: "지난 5년간 주가 성장률",
    PriceGrowthoverthepast1Years: "지난 1년간 주가 성장률",
    Profitability: "수익성 (분기별)",
    GrowingRevenue: "매출 성장 (분기별)",
    GrowingNetProfit: "순이익 성장 (분기별)",
    ProfitabilityAnnual: "수익성 (연간)",
    GrowingRevenueAnnual: "매출 성장 (연간)",
    GrowingNetProfitAnnual: "순이익 성장 (연간)",
    FinancialMetrics: "평가 요약",
    BalanceSheet: "재무 지표",
    Cash: "현금",
    Debt: "부채",
    CashDebtRatio: "현금 부채 비율",
    DividendYield: "배당 수익률",
    PayoutRatio: "배당 성향",
    FiveYearAvgDividendYield: "5년 평균 배당 수익률",
    DividendGrowthRateoverthepast5Years: "지난 5년간 배당 성장률",
    QualityOfCompany: "기업 품질",
    Dividend: "배당",
    comparedToLastQuarter: "지난 분기와 비교",
    comparedToLastYear: "지난해와 비교",
    newTab: "새 탭",
    compare: "비교하다",
    topCompanies: "최고 기업",
    watchlist: "감시 목록",
    rank: "순위",
    companyName: "회사 이름",
    symbol: "기호",
    marketCap: "시가총액",
    price: "가격",
    today: "오늘",
    volume: "거래량",
    country: "국가",
    joinDiscord: "디스코드에 참여하기",
    joinTelegram: "텔레그램에 참여하기",
    contactUs: "문의하기",
    InTheLast365Days: "지난 365일 동안",
    LastUpdated: "마지막 업데이트",
    pages: "페이지",

    titles: {
      Price: "가격",
      MarketCap: "시가총액",
      Revenue: "수익",
      RevenueVsNetIncome: "수익 대 순이익",
      NetIncome: "순이익",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "총비용",
      SharesIssued: "발행 주식",
      FreeCashFlow: "자유 현금 흐름",
      OperatingCashFlow: "영업 현금 흐름",
      Dividend: "배당",
      GrossMargin: "매출총이익률",
      CashVsDebt: "현금 대 부채",
    },
    description: {
      Price: "시간에 따른 회사 주가 변동",
      MarketCap: "시간에 따른 회사의 발행 주식 총 가치",
      Revenue:
        "시간에 따른 비즈니스 활동, 상품 및 서비스 판매에서 발생한 총 수익",
      RevenueVsNetIncome:
        "시간에 따른 회사의 총 수익과 순이익 비교로, 회사 성장의 지속 가능성을 나타냅니다. 낮은 비율은 회사가 경쟁 우위를 개발하고 시장 점유율을 확보하고 있음을 나타낼 수 있습니다",
      NetIncome:
        "시간에 따른 총 수익에서 모든 비용, 세금 및 비용을 공제한 후 회사의 이익",
      Ebitda:
        "시간에 따른 회사의 이자, 세금, 감가 상각비 및 상각비 차감 전 이익. 핵심 운영에서 회사의 전반적인 재무 성과와 수익성을 측정합니다",
      Eps: "발행된 보통주 한 주당 할당된 회사의 이익 비율. 주주가 소유한 각 주식에 대해 얼마를 받을지 나타냅니다",
      TotalExpenses:
        "비즈니스 수행 시 발생하는 모든 비용의 합계, 운영 비용, 이자, 세금 등을 포함합니다. 그래프는 시간에 따른 총비용을 보여주어 비용 동향과 효율성을 나타낼 수 있습니다",
      SharesIssued: "회사의 총 발행 주식 수",
      FreeCashFlow:
        "운영 지원 및 자본 자산 유지를 위한 현금 유출을 고려한 후 회사가 생성하는 현금 흐름. 배당, 부채 상환 또는 재투자에 사용할 수 있는 자금을 나타냅니다",
      OperatingCashFlow:
        "시간에 따른 회사의 비즈니스 운영에서 생성된 현금 흐름. 회사가 운영을 유지하고 성장시키기에 충분한 긍정적인 현금 흐름을 생성할 수 있는지 여부를 보여줍니다",
      Dividend:
        "일반적으로 현금 지급 형태로 주주에게 배분되는 회사 수익의 일부입니다",
      GrossMargin:
        "판매한 상품 원가(COGS)를 공제한 후 남은 수익의 비율. 회사가 제품을 효율적으로 생산 및 판매하고 있는지를 나타냅니다",
      CashVsDebt:
        "회사의 현금 준비금과 총 부채를 비교하는 그래프. 회사의 재정 상태와 유동성을 평가하는 데 도움이 됩니다",
    },
    dataDescriptions: {
      MarketCap: "회사의 주식 총 가치; 주가를 총 주식 수로 곱하여 계산합니다.",
      PE: "주가수익비율(P/E)은 투자자들이 회사의 수익 1달러당 지불할 의향이 있는 금액을 측정하는 지표입니다. 주가를 주당 수익으로 나누어 계산합니다. 높은 P/E 비율은 투자자들이 높은 미래 성장률을 기대하거나 주식이 과대평가되었을 수 있음을 나타낼 수 있습니다.",
      PS: "주가매출비율(P/S)은 투자자들이 회사의 매출 1달러당 지불하는 금액을 측정하는 지표입니다. 주가를 주당 매출로 나누어 계산합니다. 높은 P/S 비율은 투자자들이 높은 미래 매출 성장을 기대하거나 주식이 과대평가되었을 수 있음을 나타낼 수 있습니다.",
      PB: "주가순자산비율(P/B)은 회사의 시장 가치를 장부가치(자산에서 부채를 뺀 값)와 비교하는 비율입니다. 주가를 주당 장부가치로 나누어 계산합니다. 높은 P/B 비율은 회사의 미래 성과에 대한 강한 투자자 신뢰를 나타낼 수 있으며, 회사가 강력한 자산 성장 없이는 주식이 과대평가될 수 있습니다.",
      FCFYield:
        "자유현금흐름 수익률(FCF Yield)은 회사가 시장 가치에 비해 생성하는 자유 현금 흐름의 양을 측정하는 지표입니다. 자유 현금 흐름을 시장 가치로 나누어 계산합니다.",
      PEGRatio:
        "주가수익성장비율(PEG)은 회사의 P/E 비율을 수익 성장률과 관련하여 고려하는 평가 지표입니다. 주식이 과대평가되었는지, 과소평가되었는지를 확인하는 데 도움이 됩니다. PEG 비율이 1인 경우 공정 가치, 1 미만인 경우 성장률에 비해 과소평가, 1 초과인 경우 성장률에 비해 과대평가를 나타냅니다.",
      OverallRisk:
        "투자가 얼마나 위험한지를 평가하는 지표로, 변동성, 재정 안정성 및 시장 상황과 같은 요소를 고려합니다. 위험이 낮을수록 일반적으로 더 좋지만, 투자자의 위험 감수성에 따라 달라질 수 있습니다.",
      week52HighPrice:
        "주식이 지난 1년 동안 도달한 최고 가격; 52주 최고가 근처에서 거래되는 주식은 최근 강력한 성과를 나타낼 수 있지만, 과대평가되었을 수도 있습니다.",
      ProfitabilityAnnual:
        "회사가 지난 1년 동안 지출한 금액보다 더 많은 돈을 벌었는지 여부; 수익성은 일반적으로 회사의 재정 건강을 나타내는 좋은 신호입니다.",
      GrowingRevenueAnnual:
        "회사의 매출이 지난 해에 비해 증가했는지 여부; 지속적인 매출 성장은 일반적으로 사업 확장의 긍정적인 신호입니다.",
      GrowingNetProfitAnnual:
        "회사의 순이익(경비 후 수익)이 지난 해에 비해 증가했는지 여부; 순이익 증가가 재무 성과 개선의 강력한 지표입니다.",
      PriceGrowthoverthepast5Years:
        "주식 가격이 지난 5년 동안 증가한 비율; 높은 비율은 장기적인 성과가 더 좋음을 나타냅니다.",
      PriceGrowthoverthepast1Years:
        "주식 가격이 지난 1년 동안 증가한 비율; 강력한 단기 성장은 긍정적이지만 다른 요소와 함께 고려해야 합니다.",
      Cash: "회사가 즉시 사용할 수 있는 총 유동 자금; 더 많은 현금은 일반적으로 재정적 유연성을 높입니다.",
      Debt: "회사가 대출자에게 빚진 총 금액; 높은 부채는 회사가 이를 상환할 만큼의 충분한 소득을 생성하지 못하면 위험할 수 있습니다.",
      CashDebtRatio:
        "회사가 현금으로 부채를 상환할 수 있는 능력의 측정 지표; 비율이 높을수록 재정 건강이 더 좋습니다.",
      Profitability:
        "회사가 가장 최근 분기 동안 지출한 금액보다 더 많은 돈을 벌었는지 여부; 분기별로 수익성이 있는 것은 회사가 일관되게 수익을 생성하고 있음을 보여줍니다.",
      GrowingRevenue:
        "회사의 매출이 이전 분기와 비교하여 증가했는지 여부; 지속적인 매출 성장은 지속적인 사업 성공의 신호입니다.",
      GrowingNetProfit:
        "회사의 순이익이 이전 분기와 비교하여 증가했는지 여부; 분기별 순이익 증가가 단기적인 재무 성과 개선을 나타냅니다.",
      DividendYield:
        "회사가 주주에게 지급하는 연간 배당금, 주가에 대한 비율로 표현됩니다; 높은 배당 수익률은 주가에 비해 더 많은 배당금 수익을 나타낼 수 있습니다.",
      PayoutRatio:
        "회사가 주주에게 배당금으로 지급한 수익의 비율; 비율이 낮으면 회사가 성장을 위해 더 많은 수익을 보유하고 있음을 나타내며, 매우 높은 비율은 배당금이 지속 불가능할 수 있음을 나타낼 수 있습니다.",
      FiveYearAvgDividendYield:
        "회사가 지난 5년 동안 제공한 평균 배당 수익률; 이는 투자자가 배당금 지급의 일관성이나 안정성을 볼 수 있도록 도와줍니다.",
    },
    labels: {
      TotalCash: "총 현금",
      TotalDebt: "총 부채",
      Revenue: "수익",
      NetIncome: "순이익",
    },
    Quarterly: "분기별",
    Annually: "연간",
    FullScreen: "전체 화면",
    ExitFullScreen: "전체 화면 종료",
    homeMetaTitle: "StockPenguins | 무료 글로벌 주식 스캐너",
    homeMetaDescription:
      "Apple, Tesla, Amazon 및 Microsoft와 같은 미국 주식을 위한 궁극의 무료 주식 스캐너 앱을 발견하세요. 가격, 시가총액, 수익, 순이익, EBITDA, 잉여현금흐름, EPS, 배당금, P/E 비율 등 자세한 지표에 액세스할 수 있습니다. 주식을 손쉽게 분석하고 비교하여 정보에 입각한 투자 결정을 내리세요. 지금 검색하세요!",
    pageMetaTitle: "주식 지표 | P/E, 수익 및 시가총액",
    pageMetaDescription:
      "가격, 시가총액, 수익, 순이익, EBITDA, 잉여현금흐름, EPS, 배당금, P/E 비율 등 무료 지표를 발견하세요. Apple의 재무 성과에 대한 정보를 유지하세요. 지금 탐색하세요!",
  },
  [LANGUAGES[9].value]: {
    // Vietnamese
    welcome: "Chào mừng",
    SearchBarPlaceholder: "Cổ phiếu nào sẽ tăng gấp 10 lần tiếp theo của bạn?",
    Search: "Tìm kiếm",
    Industry: "Ngành",
    Sector: "Lĩnh vực",
    MarketCap: "Vốn hóa thị trường",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Tỷ suất FCF",
    PEGRatio: "Tỷ lệ PEG",
    OverallRisk: "Rủi ro tổng thể",
    week52HighPrice: "Giá cao nhất 52 tuần",
    PriceGrowthoverthepast5Years: "Tăng trưởng giá trong 5 năm qua",
    PriceGrowthoverthepast1Years: "Tăng trưởng giá trong năm qua",
    Profitability: "Có lãi? (Quý)",
    GrowingRevenue: "Doanh thu tăng trưởng? (Quý)",
    GrowingNetProfit: "Lợi nhuận ròng tăng trưởng? (Quý)",
    ProfitabilityAnnual: "Có lãi? (Hàng năm)",
    GrowingRevenueAnnual: "Doanh thu tăng trưởng? (Hàng năm)",
    GrowingNetProfitAnnual: "Lợi nhuận ròng tăng trưởng? (Hàng năm)",
    FinancialMetrics: "Tóm tắt định giá",
    BalanceSheet: "Chỉ số tài chính",
    Cash: "Tiền mặt",
    Debt: "Nợ",
    CashDebtRatio: "Tỷ lệ Tiền mặt/Nợ",
    DividendYield: "Tỷ suất cổ tức",
    PayoutRatio: "Tỷ lệ chi trả",
    FiveYearAvgDividendYield: "Tỷ suất cổ tức trung bình 5 năm",
    DividendGrowthRateoverthepast5Years:
      "Tỷ lệ tăng trưởng cổ tức trong 5 năm qua",
    QualityOfCompany: "Chất lượng công ty",
    Dividend: "Cổ tức",
    comparedToLastQuarter: "So với quý trước",
    comparedToLastYear: "So với năm trước",
    newTab: "Thẻ mới",
    compare: "So sánh",
    topCompanies: "Công ty hàng đầu",
    watchlist: "Danh sách theo dõi",
    rank: "Hạng",
    companyName: "Tên công ty",
    symbol: "Ký hiệu",
    marketCap: "Vốn hóa thị trường",
    price: "Giá",
    today: "Hôm nay",
    volume: "Khối lượng",
    country: "Quốc gia",
    joinDiscord: "Tham gia Discord",
    joinTelegram: "Tham gia Telegram",
    contactUs: "Liên hệ với chúng tôi",
    InTheLast365Days: "Trong 365 ngày qua",
    LastUpdated: "Cập nhật lần cuối",
    pages: "Trang",

    titles: {
      Price: "Giá",
      MarketCap: "Vốn hóa thị trường",
      Revenue: "Doanh thu",
      RevenueVsNetIncome: "Doanh thu vs. Thu nhập ròng",
      NetIncome: "Thu nhập ròng",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Tổng chi phí",
      SharesIssued: "Cổ phiếu đang lưu hành",
      FreeCashFlow: "Dòng tiền tự do",
      OperatingCashFlow: "Dòng tiền hoạt động",
      Dividend: "Cổ tức",
      GrossMargin: "Biên lợi nhuận gộp",
      CashVsDebt: "Tiền mặt vs. Nợ",
    },
    description: {
      Price: "Giá cổ phiếu lịch sử của công ty theo thời gian",
      MarketCap:
        "Tổng giá trị cổ phiếu lưu hành của một công ty theo thời gian",
      Revenue:
        "Tổng số tiền tạo ra từ các hoạt động kinh doanh, bán hàng hóa và dịch vụ theo thời gian",
      RevenueVsNetIncome:
        "So sánh giữa tổng doanh thu và thu nhập ròng của công ty theo thời gian, cho thấy tính bền vững của sự tăng trưởng của công ty. Tỷ lệ thấp hơn có thể chỉ ra rằng công ty đang phát triển lợi thế cạnh tranh và chiếm lĩnh thị phần",
      NetIncome:
        "Lợi nhuận mà công ty tạo ra sau khi đã trừ hết chi phí, thuế và chi phí từ tổng doanh thu theo thời gian",
      Ebitda:
        "Lợi nhuận trước lãi vay, thuế, khấu hao và khấu trừ của một công ty theo thời gian. Đo lường hiệu quả tài chính tổng thể và khả năng sinh lời của công ty từ các hoạt động cốt lõi",
      Eps: "Phần lợi nhuận của công ty được phân bổ cho mỗi cổ phiếu phổ thông đang lưu hành. Nó cho biết các cổ đông sẽ nhận được bao nhiêu tiền cho mỗi cổ phiếu họ sở hữu",
      TotalExpenses:
        "Tổng số chi phí mà công ty phải chịu trong việc thực hiện kinh doanh, bao gồm chi phí hoạt động, lãi vay, thuế, v.v. Biểu đồ có thể hiển thị tổng chi phí theo thời gian, chỉ ra xu hướng và hiệu quả chi phí",
      SharesIssued: "Tổng số cổ phiếu mà công ty đã phát hành",
      FreeCashFlow:
        "Dòng tiền mà công ty tạo ra sau khi tính đến dòng tiền ra để hỗ trợ hoạt động và duy trì tài sản cố định. Nó đại diện cho số tiền có sẵn để trả cổ tức, trả nợ hoặc tái đầu tư",
      OperatingCashFlow:
        "Dòng tiền tạo ra từ hoạt động kinh doanh của công ty theo thời gian. Nó cho thấy liệu công ty có thể tạo ra đủ dòng tiền dương để duy trì và phát triển hoạt động của mình hay không",
      Dividend:
        "Một phần lợi nhuận của công ty được phân phối cho các cổ đông, thường dưới dạng thanh toán bằng tiền mặt",
      GrossMargin:
        "Tỷ lệ phần trăm doanh thu còn lại sau khi trừ chi phí hàng hóa đã bán (COGS). Nó cho thấy công ty sản xuất và bán sản phẩm hiệu quả như thế nào",
      CashVsDebt:
        "Biểu đồ so sánh dự trữ tiền mặt của công ty với tổng nợ của nó. Nó giúp đánh giá tình hình tài chính và thanh khoản của công ty",
    },
    dataDescriptions: {
      MarketCap:
        "Tổng giá trị của cổ phiếu công ty; được tính bằng cách nhân giá cổ phiếu với tổng số cổ phiếu.",
      PE: "Tỷ lệ giá trên lợi nhuận (P/E) đo lường số tiền mà các nhà đầu tư sẵn sàng trả cho mỗi đô la lợi nhuận của công ty; được tính bằng cách chia giá cổ phiếu cho lợi nhuận trên mỗi cổ phiếu. Tỷ lệ P/E cao có thể cho thấy các nhà đầu tư kỳ vọng tỷ lệ tăng trưởng cao trong tương lai hoặc cổ phiếu có thể bị định giá quá cao.",
      PS: "Tỷ lệ giá trên doanh thu (P/S) đo lường số tiền mà các nhà đầu tư trả cho mỗi đô la doanh thu của công ty; được tính bằng cách chia giá cổ phiếu cho doanh thu trên mỗi cổ phiếu. Tỷ lệ P/S cao có thể cho thấy các nhà đầu tư kỳ vọng tăng trưởng doanh thu cao trong tương lai hoặc cổ phiếu có thể bị định giá quá cao.",
      PB: "Tỷ lệ giá trên giá trị sổ sách (P/B) là tỷ lệ so sánh giá trị thị trường của công ty với giá trị sổ sách của nó (tài sản trừ nợ); được tính bằng cách chia giá cổ phiếu cho giá trị sổ sách trên mỗi cổ phiếu. Tỷ lệ P/B cao có thể cho thấy sự tự tin mạnh mẽ của nhà đầu tư vào hiệu suất tương lai của công ty hoặc cổ phiếu có thể bị định giá quá cao nếu công ty không có sự tăng trưởng tài sản mạnh mẽ.",
      FCFYield:
        "Tỷ suất lợi nhuận từ dòng tiền tự do (FCF Yield) đo lường mức độ dòng tiền tự do mà công ty tạo ra so với giá trị thị trường của nó; được tính bằng cách chia dòng tiền tự do cho giá trị thị trường.",
      PEGRatio:
        "Tỷ lệ giá/lợi nhuận trên tăng trưởng (PEG) là một chỉ số định giá xem xét tỷ lệ P/E của công ty liên quan đến tỷ lệ tăng trưởng lợi nhuận của nó; giúp xác định xem cổ phiếu có bị định giá quá cao hay quá thấp. Tỷ lệ PEG bằng 1 cho thấy giá trị hợp lý, dưới 1 cho thấy định giá thấp so với tỷ lệ tăng trưởng, và trên 1 cho thấy định giá cao so với tỷ lệ tăng trưởng.",
      OverallRisk:
        "Đánh giá mức độ rủi ro của một khoản đầu tư, xét đến các yếu tố như biến động, sự ổn định tài chính và điều kiện thị trường; rủi ro thấp thường tốt hơn, nhưng phụ thuộc vào mức độ chấp nhận rủi ro của nhà đầu tư.",
      week52HighPrice:
        "Giá cao nhất mà cổ phiếu đã đạt được trong năm qua; cổ phiếu giao dịch gần mức cao nhất trong 52 tuần có thể cho thấy hiệu suất gần đây mạnh mẽ, nhưng cũng có thể cho thấy nó bị định giá quá cao.",
      ProfitabilityAnnual:
        "Công ty có kiếm được nhiều tiền hơn số tiền đã chi trong năm qua không; tính hiệu quả là dấu hiệu tốt cho sức khỏe tài chính của công ty.",
      GrowingRevenueAnnual:
        "Doanh thu của công ty có tăng so với năm trước không; sự tăng trưởng doanh thu liên tục thường là dấu hiệu tích cực của sự mở rộng kinh doanh.",
      GrowingNetProfitAnnual:
        "Lợi nhuận ròng của công ty (thu nhập sau chi phí) có tăng so với năm trước không; lợi nhuận ròng tăng là chỉ số mạnh mẽ về sự cải thiện hiệu suất tài chính.",
      PriceGrowthoverthepast5Years:
        "Tỷ lệ tăng giá cổ phiếu trong 5 năm qua; tỷ lệ cao hơn cho thấy hiệu suất dài hạn tốt hơn.",
      PriceGrowthoverthepast1Years:
        "Tỷ lệ tăng giá cổ phiếu trong năm qua; tăng trưởng ngắn hạn mạnh mẽ là tích cực nhưng nên được xem xét trong bối cảnh các yếu tố khác.",
      Cash: "Tổng số tiền lỏng mà công ty có sẵn để sử dụng ngay lập tức; nhiều tiền mặt thường tốt hơn cho sự linh hoạt tài chính.",
      Debt: "Tổng số tiền mà công ty nợ các chủ nợ; nợ cao có thể rủi ro nếu công ty không thể tạo ra đủ thu nhập để trả nợ.",
      CashDebtRatio:
        "Đo lường khả năng của công ty để thanh toán nợ bằng tiền mặt sẵn có; tỷ lệ cao hơn cho thấy sức khỏe tài chính tốt hơn.",
      Profitability:
        "Công ty có kiếm được nhiều tiền hơn số tiền đã chi trong quý gần nhất không; việc có lợi nhuận hàng quý cho thấy công ty đang liên tục tạo ra thu nhập.",
      GrowingRevenue:
        "Doanh thu của công ty có tăng so với quý trước không; tăng trưởng doanh thu liên tục là dấu hiệu của sự thành công kinh doanh liên tục.",
      GrowingNetProfit:
        "Lợi nhuận ròng của công ty có tăng so với quý trước không; lợi nhuận ròng tăng hàng quý cho thấy sự cải thiện hiệu suất tài chính trong ngắn hạn.",
      DividendYield:
        "Khoản thanh toán cổ tức hàng năm mà công ty trả cho các cổ đông, được biểu thị dưới dạng tỷ lệ phần trăm của giá cổ phiếu; tỷ suất cổ tức cao hơn có thể cho thấy thu nhập từ cổ tức nhiều hơn so với giá cổ phiếu.",
      PayoutRatio:
        "Tỷ lệ phần trăm lợi nhuận của công ty được trả dưới dạng cổ tức cho các cổ đông; tỷ lệ thấp hơn cho thấy công ty giữ lại nhiều lợi nhuận hơn để phát triển, trong khi tỷ lệ rất cao có thể cho thấy cổ tức có thể không bền vững.",
      FiveYearAvgDividendYield:
        "Tỷ suất cổ tức trung bình mà công ty đã cung cấp trong 5 năm qua; giúp các nhà đầu tư thấy mức độ nhất quán hoặc ổn định của việc thanh toán cổ tức theo thời gian.",
    },
    labels: {
      TotalCash: "Tổng tiền mặt",
      TotalDebt: "Tổng nợ",
      Revenue: "Doanh thu",
      NetIncome: "Thu nhập ròng",
    },
    Quarterly: "Hàng quý",
    Annually: "Hàng năm",
    FullScreen: "Toàn màn hình",
    ExitFullScreen: "Thoát chế độ toàn màn hình",
    homeMetaTitle: "StockPenguins | Máy Quét Cổ Phiếu Toàn Cầu Miễn Phí",
    homeMetaDescription:
      "Khám phá ứng dụng quét cổ phiếu miễn phí tuyệt vời nhất cho cổ phiếu Mỹ như Apple, Tesla, Amazon và Microsoft. Truy cập các chỉ số chi tiết như Giá, Vốn Hóa Thị Trường, Doanh Thu, Lợi Nhuận Ròng, EBITDA, Dòng Tiền Tự Do, EPS, Cổ Tức, Tỷ Lệ P/E và nhiều hơn nữa. Phân tích và so sánh cổ phiếu một cách dễ dàng để đưa ra quyết định đầu tư thông minh. Tìm kiếm ngay!",
    pageMetaTitle: "Chỉ Số Cổ Phiếu | P/E, Doanh Thu & Vốn Hóa Thị Trường",
    pageMetaDescription:
      "Khám phá các chỉ số miễn phí, bao gồm Giá, Vốn Hóa Thị Trường, Doanh Thu, Lợi Nhuận Ròng, EBITDA, Dòng Tiền Tự Do, EPS, Cổ Tức, Tỷ Lệ P/E và nhiều hơn nữa. Cập nhật thông tin về hiệu suất tài chính của Apple. Khám phá ngay!",
  },
  [LANGUAGES[10].value]: {
    // Bahasa Indonesia
    welcome: "Selamat datang",
    SearchBarPlaceholder: "Apa investasi 10 kali lipat berikutnya Anda?",
    Search: "Cari",
    Industry: "Industri",
    Sector: "Sektor",
    MarketCap: "Kapitalisasi Pasar",
    PE: "P / E",
    PS: "P / S",
    PB: "P / B",
    FCFYield: "Hasil FCF",
    PEGRatio: "Rasio PEG",
    OverallRisk: "Risiko Keseluruhan",
    week52HighPrice: "Harga Tertinggi 52 Minggu",
    PriceGrowthoverthepast5Years: "Pertumbuhan Harga dalam 5 Tahun Terakhir",
    PriceGrowthoverthepast1Years: "Pertumbuhan Harga dalam 1 Tahun Terakhir",
    Profitability: "Laba? (Triwulanan)",
    GrowingRevenue: "Pendapatan tumbuh? (Triwulanan)",
    GrowingNetProfit: "Keuntungan bersih tumbuh? (Triwulanan)",
    ProfitabilityAnnual: "Laba? (Tahunan)",
    GrowingRevenueAnnual: "Pendapatan tumbuh? (Tahunan)",
    GrowingNetProfitAnnual: "Keuntungan bersih tumbuh? (Tahunan)",
    FinancialMetrics: "Ringkasan Penilaian",
    BalanceSheet: "Metrik Keuangan",
    Cash: "Kas",
    Debt: "Utang",
    CashDebtRatio: "Rasio Kas/Utang",
    DividendYield: "Imbal Hasil Dividen",
    PayoutRatio: "Rasio Pembayaran",
    FiveYearAvgDividendYield: "Imbal Hasil Dividen Rata-Rata 5 Tahun",
    DividendGrowthRateoverthepast5Years:
      "Tingkat Pertumbuhan Dividen dalam 5 Tahun Terakhir",
    QualityOfCompany: "Kualitas Perusahaan",
    Dividend: "Dividen",
    comparedToLastQuarter: "Dibandingkan dengan Kuartal Terakhir",
    comparedToLastYear: "Dibandingkan dengan Tahun Lalu",
    newTab: "Tab baru",
    compare: "Bandingkan",
    topCompanies: "Perusahaan Teratas",
    watchlist: "Daftar Pantauan",
    rank: "Peringkat",
    companyName: "Nama Perusahaan",
    symbol: "Simbol",
    marketCap: "Kapitalisasi Pasar",
    price: "Harga",
    today: "Hari ini",
    volume: "Volume",
    country: "Negara",
    joinDiscord: "Bergabung dengan Discord",
    joinTelegram: "Bergabung dengan Telegram",
    contactUs: "Hubungi Kami",
    InTheLast365Days: "Dalam 365 hari terakhir",
    LastUpdated: "Terakhir diperbarui",
    pages: "Halaman",

    titles: {
      Price: "Harga",
      MarketCap: "Kapitalisasi Pasar",
      Revenue: "Pendapatan",
      RevenueVsNetIncome: "Pendapatan vs. Laba Bersih",
      NetIncome: "Laba Bersih",
      Ebitda: "EBITDA",
      Eps: "EPS",
      TotalExpenses: "Total Biaya",
      SharesIssued: "Saham Beredar",
      FreeCashFlow: "Arus Kas Bebas",
      OperatingCashFlow: "Arus Kas Operasional",
      Dividend: "Dividen",
      GrossMargin: "Margin Kotor",
      CashVsDebt: "Kas vs. Utang",
    },
    description: {
      Price: "Harga saham historis perusahaan dari waktu ke waktu",
      MarketCap:
        "Nilai total saham yang beredar dari sebuah perusahaan dari waktu ke waktu",
      Revenue:
        "Jumlah uang yang dihasilkan dari kegiatan bisnis, penjualan barang dan jasa dari waktu ke waktu",
      RevenueVsNetIncome:
        "Perbandingan antara total pendapatan dan laba bersih perusahaan dari waktu ke waktu, menunjukkan seberapa berkelanjutan pertumbuhan perusahaan tersebut. Rasio yang lebih rendah dapat mengindikasikan bahwa perusahaan sedang mengembangkan keunggulan kompetitif dan mengambil pangsa pasar",
      NetIncome:
        "Keuntungan yang diperoleh sebuah perusahaan setelah semua biaya, pajak, dan biaya telah dikurangkan dari total pendapatan dari waktu ke waktu",
      Ebitda:
        "Pendapatan sebelum bunga, pajak, depresiasi, dan amortisasi sebuah perusahaan dari waktu ke waktu. Ini mengukur kinerja keuangan dan profitabilitas keseluruhan perusahaan dari operasi inti",
      Eps: "Bagian dari laba perusahaan yang dialokasikan untuk setiap saham biasa yang beredar. Ini menunjukkan berapa banyak uang yang akan diterima pemegang saham untuk setiap saham yang mereka miliki",
      TotalExpenses:
        "Jumlah semua biaya yang ditanggung oleh sebuah perusahaan dalam menjalankan bisnisnya, termasuk biaya operasional, bunga, pajak, dll. Grafik dapat menunjukkan total biaya dari waktu ke waktu, mengindikasikan tren biaya dan efisiensi",
      SharesIssued:
        "Jumlah total saham yang dikeluarkan oleh sebuah perusahaan",
      FreeCashFlow:
        "Uang tunai yang dihasilkan sebuah perusahaan setelah mempertimbangkan aliran keluar untuk mendukung operasi dan mempertahankan aset modal. Ini mewakili uang yang tersedia untuk dividen, pembayaran utang, atau reinvestasi",
      OperatingCashFlow:
        "Uang tunai yang dihasilkan dari operasi bisnis sebuah perusahaan dari waktu ke waktu. Ini menunjukkan apakah sebuah perusahaan dapat menghasilkan cukup arus kas positif untuk mempertahankan dan mengembangkan operasinya",
      Dividend:
        "Sebagian dari laba sebuah perusahaan yang didistribusikan kepada pemegang saham, biasanya dalam bentuk pembayaran tunai",
      GrossMargin:
        "Persentase pendapatan yang tersisa setelah dikurangi biaya barang yang dijual (COGS). Ini menunjukkan seberapa efisien sebuah perusahaan dalam memproduksi dan menjual produknya",
      CashVsDebt:
        "Perbandingan grafis antara cadangan kas perusahaan dengan total utangnya. Ini membantu menilai kesehatan keuangan dan likuiditas perusahaan",
    },
    dataDescriptions: {
      MarketCap:
        "Total nilai saham perusahaan; dihitung dengan mengalikan harga saham dengan total jumlah saham.",
      PE: "Rasio Harga terhadap Laba (P/E) mengukur seberapa banyak investor bersedia membayar untuk setiap dolar laba perusahaan; dihitung dengan membagi harga saham dengan laba per saham. Rasio P/E yang tinggi dapat menunjukkan bahwa investor mengharapkan tingkat pertumbuhan masa depan yang tinggi atau saham mungkin dinilai terlalu tinggi.",
      PS: "Rasio Harga terhadap Penjualan (P/S) mengukur seberapa banyak investor membayar untuk setiap dolar penjualan perusahaan; dihitung dengan membagi harga saham dengan penjualan per saham. Rasio P/S yang tinggi dapat menunjukkan bahwa investor mengharapkan pertumbuhan penjualan masa depan yang tinggi atau saham mungkin dinilai terlalu tinggi.",
      PB: "Rasio Harga terhadap Nilai Buku (P/B) adalah rasio yang membandingkan nilai pasar perusahaan dengan nilai bukunya (aset dikurangi kewajiban); dihitung dengan membagi harga saham dengan nilai buku per saham. Rasio P/B yang tinggi dapat menunjukkan keyakinan investor yang kuat pada kinerja masa depan perusahaan atau bahwa saham mungkin dinilai terlalu tinggi jika perusahaan tidak memiliki pertumbuhan aset yang kuat.",
      FCFYield:
        "Tingkat Hasil Arus Kas Bebas (FCF Yield) adalah ukuran seberapa banyak arus kas bebas yang dihasilkan perusahaan dibandingkan dengan nilai pasar perusahaan; dihitung dengan membagi arus kas bebas dengan nilai pasar.",
      PEGRatio:
        "Rasio Harga/Laba terhadap Pertumbuhan (PEG) adalah metrik penilaian yang mempertimbangkan rasio P/E perusahaan terkait dengan tingkat pertumbuhan laba; membantu melihat apakah saham dinilai terlalu tinggi atau terlalu rendah. Rasio PEG sebesar 1 menunjukkan nilai wajar, di bawah 1 menunjukkan penilaian rendah dibandingkan dengan tingkat pertumbuhan, dan di atas 1 menunjukkan penilaian tinggi dibandingkan dengan tingkat pertumbuhan.",
      OverallRisk:
        "Penilaian tentang seberapa berisiko suatu investasi, mempertimbangkan faktor-faktor seperti volatilitas, stabilitas keuangan, dan kondisi pasar; risiko yang lebih rendah biasanya lebih baik, tetapi tergantung pada toleransi risiko investor.",
      week52HighPrice:
        "Harga tertinggi yang dicapai saham dalam tahun lalu; saham yang diperdagangkan mendekati harga tertinggi 52 minggu mungkin menunjukkan kinerja yang kuat baru-baru ini, tetapi juga dapat menunjukkan bahwa saham tersebut dinilai terlalu tinggi.",
      ProfitabilityAnnual:
        "Apakah perusahaan telah menghasilkan lebih banyak uang daripada yang dibelanjakan selama tahun lalu; profitabilitas umumnya merupakan tanda baik dari kesehatan keuangan perusahaan.",
      GrowingRevenueAnnual:
        "Apakah pendapatan (penjualan) perusahaan telah meningkat dibandingkan dengan tahun sebelumnya; pertumbuhan pendapatan yang konsisten biasanya merupakan tanda positif dari ekspansi bisnis.",
      GrowingNetProfitAnnual:
        "Apakah laba bersih (pendapatan setelah biaya) perusahaan telah meningkat dibandingkan dengan tahun sebelumnya; laba bersih yang meningkat adalah indikator kuat dari perbaikan kinerja keuangan.",
      PriceGrowthoverthepast5Years:
        "Persentase kenaikan harga saham selama 5 tahun terakhir; persentase yang lebih tinggi menunjukkan kinerja jangka panjang yang lebih baik.",
      PriceGrowthoverthepast1Years:
        "Persentase kenaikan harga saham selama tahun lalu; pertumbuhan jangka pendek yang kuat adalah positif tetapi harus dipertimbangkan dalam konteks faktor lainnya.",
      Cash: "Jumlah total uang tunai yang tersedia bagi perusahaan untuk digunakan segera; lebih banyak uang tunai umumnya lebih baik untuk fleksibilitas keuangan.",
      Debt: "Jumlah total uang yang perusahaan berutang kepada pemberi pinjaman; utang yang tinggi dapat berisiko jika perusahaan tidak dapat menghasilkan pendapatan yang cukup untuk membayar utangnya.",
      CashDebtRatio:
        "Ukuran kemampuan perusahaan untuk membayar utangnya dengan uang tunai yang tersedia; rasio yang lebih tinggi menunjukkan kesehatan keuangan yang lebih baik.",
      Profitability:
        "Apakah perusahaan menghasilkan lebih banyak uang daripada yang dibelanjakan pada kuartal terbaru; menjadi menguntungkan setiap kuartal menunjukkan bahwa perusahaan secara konsisten menghasilkan pendapatan.",
      GrowingRevenue:
        "Apakah pendapatan perusahaan meningkat dibandingkan dengan kuartal sebelumnya; pertumbuhan pendapatan yang berkelanjutan adalah tanda keberhasilan bisnis yang berkelanjutan.",
      GrowingNetProfit:
        "Apakah laba bersih perusahaan meningkat dibandingkan dengan kuartal sebelumnya; laba bersih yang meningkat setiap kuartal menunjukkan perbaikan kinerja keuangan dalam jangka pendek.",
      DividendYield:
        "Pembayaran dividen tahunan yang diberikan perusahaan kepada pemegang saham, dinyatakan sebagai persentase dari harga saham; hasil yang lebih tinggi dapat menunjukkan lebih banyak pendapatan dari dividen relatif terhadap harga saham.",
      PayoutRatio:
        "Persentase dari laba perusahaan yang dibayarkan sebagai dividen kepada pemegang saham; rasio yang lebih rendah menunjukkan perusahaan menyimpan lebih banyak laba untuk pertumbuhan, sementara rasio yang sangat tinggi mungkin menunjukkan dividen tersebut dapat tidak berkelanjutan.",
      FiveYearAvgDividendYield:
        "Rata-rata hasil dividen yang ditawarkan perusahaan selama lima tahun terakhir; membantu investor melihat seberapa konsisten atau stabil pembayaran dividen sepanjang waktu.",
    },
    labels: {
      TotalCash: "Total Kas",
      TotalDebt: "Total Utang",
      Revenue: "Pendapatan",
      NetIncome: "Laba Bersih",
    },
    Quarterly: "Kuartalan",
    Annually: "Secara tahunan",
    FullScreen: "Layar penuh",
    ExitFullScreen: "Keluar dari layar penuh",
    homeMetaTitle: "StockPenguins | Pemindai Saham Global Gratis",
    homeMetaDescription:
      "Temukan aplikasi pemindai saham gratis terbaik untuk saham AS seperti Apple, Tesla, Amazon, dan Microsoft. Akses metrik terperinci seperti Harga, Kapitalisasi Pasar, Pendapatan, Laba Bersih, EBITDA, Arus Kas Bebas, EPS, Dividen, Rasio P/E, dan banyak lagi. Analisis dan bandingkan saham dengan mudah untuk membuat keputusan investasi yang tepat. Cari sekarang!",
    pageMetaTitle: "Metrik Saham | P/E, Pendapatan & Kapitalisasi Pasar",
    pageMetaDescription:
      "Temukan metrik gratis, termasuk Harga, Kapitalisasi Pasar, Pendapatan, Laba Bersih, EBITDA, Arus Kas Bebas, EPS, Dividen, Rasio P/E, dan banyak lagi. Tetap terinformasi tentang kinerja keuangan Apple. Jelajahi sekarang!",
  },
};
