import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import './styles/style.css';
import Compare from './pages/Compare';
import TopCompaniesPage from './pages/TopCompanies';
import Index from './pages/Index';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/compare' element={<Compare />} />
        <Route path='/top-companies-by-market-cap' element={<TopCompaniesPage />} />
        <Route path='/companies/stock-metrics/:id' element={<HomePage />} />
        <Route path='/index/:id' element={<Index />} />
      </Routes>
    </Router>
  );
}

export default App;