import React, { StrictMode, useEffect, useMemo, useState } from "react";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import topCompanies from "../../data/topCompanies.json";
import "./home.scss";
import { nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip as Tip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { THEME_COLORS } from "../../colors";
import { COLORS } from "../../constants";
import { useParams } from "react-router-dom";

const IndexGraph = ({ lang, mode }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tip,
    Filler,
    Legend
  );

  const [rowData, setRowData] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);
  const [labels, setLabels] = useState([]);
  const [title, setTitle] = useState([]);
  const [percentageChange, setPercentageChange] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    let fullUrl = `/assets/data/${id}.json`;

    axios.get(fullUrl).then((response) => {
      let data = response.data;

      let dates = data?.data?.dates;
      let histObj = JSON.parse(data?.data?.hist);
      let priceHistory = histObj.Close;
      let dateString = [];
      let values = [];
      for (let time in priceHistory) {
        let timestamp = parseInt(time);
        dateString.push(moment(timestamp).format("MMM-YY"));
        values.push(priceHistory[time]);
      }

      let firstValue = parseFloat(values[values.length - 251]);
      let currentValue = parseFloat(values[values.length - 1]);

      let pctChange = (currentValue / firstValue - 1) * 100;
      setUpdateTime(data.updateTime);
      setTitle(data.title);
      setPercentageChange(pctChange.toFixed(2));
      setRowData(values);
      setLabels(dateString);
    });
  }, [id]);

  const options = (type) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          ticks: {
            callback: function (label, index, labels) {
              // if (yaxisFormatter) {
              //   return yaxisFormatter(label);
              // }
              return label;
            },
          },
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          // align: 'start',
          color: THEME_COLORS[mode].graph.title,
          // text: `${title}`,
          font: {
            size: 18,
          },
        },
        // tooltip: {
        //   titleFont: {
        //     size: 15,
        //   },
        //   callbacks: {
        //     label: function (tooltipItem, data) {
        //       if (tooltipFormatter) {
        //         return tooltipFormatter(tooltipItem);
        //       }
        //       return tooltipItem.formattedValue;
        //     },
        //   },
        // },
      },
    };
  };

  let data1 = {
    labels,
    datasets: [
      {
        fill: true,
        data: rowData,
        borderColor: COLORS.GRAPH.PRICE.borderColor,
        backgroundColor: COLORS.GRAPH.PRICE.backgroundColor,
      },
    ],
  };
  return (
    <>
      {rowData && (
        <>
          <span
            className="m-4"
            style={{
              position: "absolute",
              left: "20px",
              top: "10px",
              fontSize: "22px",
              fontWeight: "600",
            }}
          >
            {title}
            <span
              className={`index-pct-change ${
                percentageChange >= 0 ? "index-green" : "index-red"
              }`}
            >{`(${percentageChange >= 0 ? "+" : "-"}${Math.abs(
              percentageChange
            )}%)`}</span> <br />
            <span style={{fontSize: "14px",top: "-22px",}}>{`${localString[lang].InTheLast365Days}`}</span>
          </span>

          <span
            className="m-4"
            style={{
              position: "absolute",
              right: "0px",
              top: "-22px",
              fontSize: "12px",
            }}
          >
            {`${localString[lang].LastUpdated}: ${moment(updateTime).format("DD-MMM-YY HH:MM")}`}
          </span>
          <Stack
            className={`content m-4 ${
              mode === "dark" ? "background-dark" : "background-light"
            }`}
            gap={3}
          >
            <div
              className={`ag-theme-${
                mode === "dark" ? "quartz-dark" : "quartz"
              }`}
              style={{ padding: "20px", height: "calc(100vh - 6rem)" }}
            >
              <div style={{ paddingTop: "100px", height: "calc(100vh - 8rem)" }}>
                <Line options={options("Line")} data={data1} />
              </div>
            </div>
          </Stack>
        </>
      )}
    </>
  );
};

export default IndexGraph;
