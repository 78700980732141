import React, { useEffect, useState } from "react";
import Graph from "../home/Graph";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import moment from "moment";
import { BILLION, COLORS, DURATION } from "../../constants";
import { localString } from "../../localization/localString";

const MarketCap = ({
  index,
  selectedId,
  setSelectedId,
  duration,
  ticker,
  lang,
  mode,
  _marketCap,
}) => {
  const [labels, setLabels] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [titleChange, setTitleChange] = useState(null);

  const getCurrentShareCount = (historicalShareData, date) => {
    let currentCount = 0;
    let currentDate = 0;
    for (let i = 0; i < historicalShareData.length; i++) {
      if (
        date > historicalShareData[i].date &&
        historicalShareData[i].date > currentDate && 
        parseInt(historicalShareData[i].shareCount) > 0
      ) {
        currentCount = historicalShareData[i].shareCount;
        currentDate = historicalShareData[i].date;
      }
    }
    return currentCount;
  };

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let fullUrl = `${apiEndPoints.getMarketCapHistory}/${ticker}/${duration}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data?.data;
        let shareCountDates = data?.shareCountDates;
        let shareCount = data?.shareCount;
        let historicalDates = data?.dates;
        let historicalData = data?.hist;
        let shareCountObj = [];
        let dateString = [];
        let marketCapHistoy = [];
        let dateFormat =
          duration === DURATION.QUARTERLY ? "[Q]Q-YYYY" : "YYYY";

        for (let i = 0; i < shareCountDates?.length; i++) {
          let thisDate = moment(shareCountDates[i]).unix();
          let val = {
            date: thisDate,
            shareCount: shareCount[i],
          };

          shareCountObj.push(val);
        }

        for (let i = 0; i < historicalDates?.length; i++) {
          let timestamp = parseInt(historicalDates[i]) / 1000000;
          let thisDate = moment(timestamp).unix();
          let curShareCount = getCurrentShareCount(shareCountObj, thisDate);
          let marketCapVal =
            (parseInt(curShareCount) * parseFloat(historicalData?.Close[i])) /
            BILLION;
          if (marketCapVal > 0) {
            marketCapHistoy.push(marketCapVal);
            dateString.push(moment(timestamp).format(dateFormat));
          }
        }
        marketCapHistoy.push(_marketCap / BILLION);
        dateString.push(moment().format(dateFormat));

        let subtractor = duration === DURATION.QUARTERLY ? 64 : 252;

        let currentValue =
          marketCapHistoy?.length > 0
            ? marketCapHistoy[marketCapHistoy.length - 1]
            : "";
        let firstValue =
          marketCapHistoy?.length > 0
            ? marketCapHistoy[marketCapHistoy.length - subtractor]
            : "";

        let percentageChange =
          ((parseFloat(currentValue) - parseFloat(firstValue)) /
            Math.abs(parseFloat(firstValue))) *
          100;
        let val = isNaN(parseFloat(currentValue).toFixed(2))
          ? "-"
          : `$${parseFloat(currentValue).toFixed(2)}B`;
        setTitleValue(val);
        setTitleChange(percentageChange);

        setLabels(dateString);
        setHistoricalData(marketCapHistoy);
      });
    }
  }, [ticker, duration, _marketCap]);

  const tooltipFormatter = (tooltipItem) => {
    return `$${tooltipItem.formattedValue}B`;
  };
  const yaxisFormatter = (label) => {
    return `$${parseFloat(label).toFixed(2)}B`;
  };

  const data = () => {
    return {
      labels,
      datasets: [
        {
          fill: true,
          data: historicalData,
          borderColor: COLORS.GRAPH.MARKET_CAP.borderColor,
          backgroundColor: COLORS.GRAPH.MARKET_CAP.backgroundColor,
        },
      ],
    };
  };

  return (
    <Graph
      index={index}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      chartType={"Bar"}
      data={data}
      description={localString[lang].description.MarketCap}
      title={`${localString[lang].titles.MarketCap}`}
      lang={lang}
      tooltipFormatter={tooltipFormatter}
      yaxisFormatter={yaxisFormatter}
      mode={mode}
      titleValue={titleValue}
      titleChange={titleChange}
      subTitle={
        duration === DURATION.QUARTERLY
          ? localString[lang].comparedToLastQuarter
          : localString[lang].comparedToLastYear
      }
    />
  );
};

export default MarketCap;
