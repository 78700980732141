import React, { useEffect, useState } from "react";
import "./home.scss";

const Share = () => {
  const [shouldShow, setShouldShow] = useState(false);
  
  useEffect(() => {
    if (navigator.share) setShouldShow(true);
  }, []);

  const handleClick = () => {

    navigator
      .share({
        title: "StockPenguins - Free Global Equities Stock Scanner",
        text: "Hey, I found something Interesting. Check this out",
        url: window.location.href,
      })
      .then(() => {
        console.log("Successfully shared");
      })
      .catch((error) => {
        console.error("Something went wrong", error);
      });
  };

  return (
    <>
      {shouldShow && (
        <i onClick={handleClick} className="bi bi-share-fill share-button"></i>
      )}
    </>
  );
};

export default Share;
