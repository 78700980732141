/* eslint-disable max-len */
export const rootConfig = {
  // apiRoot: "http://localhost:8000",
  apiRoot: "https://api.stockpenguins.com",
};

export const primaryRootConfig = rootConfig.apiRoot; // primary root
export const apiEndPoints = {
  getTicker: `${primaryRootConfig}/getTicker`,
  getTickerInfo: `${primaryRootConfig}/getTickerInfo`,
  getPriceHistory: `${primaryRootConfig}/getPriceHistory`,
  getMarketCapHistory: `${primaryRootConfig}/getMarketCapHistory`,
  getPriceGrowth: `${primaryRootConfig}/getPriceGrowth`,
  getIncomeHistory: `${primaryRootConfig}/getIncomeHistory`,
};
