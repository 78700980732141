import React, { StrictMode, useEffect, useMemo, useState } from "react";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import topCompanies from "../../data/topCompanies.json";
import "./home.scss";
import { nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";

const TopCompanies = ({ lang, mode }) => {
  const [rowData, setRowData] = useState(null);
  const [updateTime, setUpdateTime] = useState(null);

  useEffect(() => {
    if (rowData === null) {
      let fullUrl = `/assets/data/topCompanies.json`;

      axios.get(fullUrl).then((response) => {
        let data = response.data;
        setUpdateTime(data.updateTime);
        let top500 = data.data.slice(0, 500);
        setRowData(top500);
      });
    }
  }, []);

  const RankColRender = (p) => {
    return `${p.node.rowIndex + 1}`;
    // <div>
    //   {p.data.rankDiff > 0 ? (
    //     <span className="chart-numbers-change-green">
    //       <i className="bi bi-arrow-up-short"></i>
    //       {Math.abs(p.data.rankDiff) || ""}
    //     </span>
    //   ) : p.data.rankDiff < 0 ? (
    //     <span className="chart-numbers-change-red">
    //       <i className="bi bi-arrow-down-short"></i>
    //       {Math.abs(p.data.rankDiff) || ""}
    //     </span>
    //   ) : (
    //     <span className="visibility-hidden">
    //       <i className="bi bi-arrow-down-short"></i>1
    //     </span>
    //   )}
    //   <span className="ps-2">{p.data.rank}</span>
    // </div>
  };

  const TodayColRender = (p) => {
    return (
      <div>
        {parseFloat(p.data.pctchange) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(parseFloat(p.data.pctchange))}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(parseFloat(p.data.pctchange))}%`}
          </span>
        )}
      </div>
    );
  };
  const MarketCapRender = (p) => {
    return `$${nFormatter(p.data.marketCap, 4)}`;
  };
  const NameRender = (p) => {
    return (<a target='__blank' href={`/companies/stock-metrics/${p.data.symbol}`}> {p.data.name} </a>)
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "rank",
      headerName: localString[lang].rank,
      cellRenderer: RankColRender,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "name",
      headerName: localString[lang].companyName,
      cellRenderer: NameRender,
      minWidth: 300,
      sortable: false,
    },
    {
      field: "symbol",
      headerName: localString[lang].symbol,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "marketCap",
      headerName: localString[lang].marketCap,
      cellRenderer: MarketCapRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "lastsale",
      headerName: localString[lang].price,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "pctchange",
      headerName: localString[lang].today,
      cellRenderer: TodayColRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "volume",
      headerName: localString[lang].volume,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "country",
      headerName: localString[lang].country,
      minWidth: 120,
      sortable: false,
    },
  ]);

  return (
    <>
      {rowData && (
        <>
          <span
            className="m-4"
            style={{
              position: "absolute",
              right: "0px",
              top: "-22px",
              fontSize: "12px",
            }}
          >
            Last Updated: {moment(updateTime).format("DD-MMM-YY HH:MM")}
          </span>
          <Stack
            className={`content m-4 ${
              mode === "dark" ? "background-dark" : "background-light"
            }`}
            gap={3}
          >
            <div
              className={`ag-theme-${
                mode === "dark" ? "quartz-dark" : "quartz"
              }`}
              style={{ height: "calc(100vh - 6rem)" }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                autoSizeStrategy={{ type: "fitGridWidth" }}
                // defaultColDef={defaultColDef}
                // rowSelection="multiple"
                // suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={25}
                paginationPageSizeSelector={[25, 50, 100]}
              />
            </div>
          </Stack>
        </>
      )}
    </>
  );
};

export default TopCompanies;
