import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Row from "react-bootstrap/Row";
import MarketCap from "../graphs/MarketCap";
import Price from "../graphs/Price";
import Revenue from "../graphs/Revenue";
import GrossMargin from "../graphs/GrossMargin";
import NetIncome from "../graphs/NetIncome";
import Ebitda from "../graphs/Ebitda";
import FreeCashFlow from "../graphs/FreeCashFlow";
import OperatingCashFlow from "../graphs/OperatingCashFlow";
import Eps from "../graphs/Eps";
import CashVsDebt from "../graphs/CashVsDebt";
import Dividend from "../graphs/Dividend";
import SharesIssued from "../graphs/SharesIssued";
import Ratios from "../graphs/Ratios";
import TotalExpenses from "../graphs/TotalExpenses";
import RevenueNetIncomeRatio from "../graphs/RevenueNetIncomeRatio";
import { DURATION } from "../../constants";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import { localString } from "../../localization/localString";
import { getRandomIDPrefix } from "../../helpers";

const Chart = ({
  ticker,
  lang,
  mode,
  threeMonthGrowth,
  oneYearGrowth,
  fiveYearGrowth,
  _marketCap,
}) => {
  const [duration, setDuration] = useState("quarterly");
  const [selectedId, setSelectedId] = useState(-1);
  const [rawData, setRawData] = useState(null);

  const randomPostfix = getRandomIDPrefix(); // Math.floor(100000 + Math.random() * 900000);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let fullUrl = `${apiEndPoints.getIncomeHistory}/${ticker}/${duration}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data?.data;
        setRawData(data);
      });
    }
  }, [ticker, duration]);

  return (
    <>
      <Stack className="chart-content" gap={3}>
        <ToggleButtonGroup
          className="chart-button-group"
          type="radio"
          name="options"
        >
          <ToggleButton
            id={`tbg-radio-1-${randomPostfix}`}
            variant={duration === DURATION.QUARTERLY ? "info" : "secondary"}
            value={DURATION.QUARTERLY}
            checked={duration === DURATION.QUARTERLY}
            onChange={(e) => {
              setDuration(e.currentTarget.value);
            }}
          >
            {localString[lang].Quarterly}
          </ToggleButton>
          <ToggleButton
            id={`tbg-radio-2-${randomPostfix}`}
            // id="tbg-radio-2"
            variant={duration === DURATION.YEARLY ? "info" : "secondary"}
            value={DURATION.YEARLY}
            checked={duration === DURATION.YEARLY}
            onChange={(e) => {
              setDuration(e.currentTarget.value);
            }}
          >
            {localString[lang].Annually}
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="d-flex flex-row flex-wrap bd-highlight chart-container">
          <Row className="chart-row">
            <Price
              index={1}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              duration={duration}
              ticker={ticker}
              mode={mode}
              lang={lang}
              threeMonthGrowth={threeMonthGrowth}
              oneYearGrowth={oneYearGrowth}
            />
            {_marketCap && (
              <MarketCap
                index={2}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                mode={mode}
                lang={lang}
                _marketCap={_marketCap}
              />
            )}
            {rawData?.incomeStatement?.hist?.revenue?.length > 0 && (
              <Revenue
                index={3}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {rawData?.incomeStatement?.hist?.revenue?.length > 0 &&
              rawData?.incomeStatement?.hist?.netIncome?.length > 0 && (
                <RevenueNetIncomeRatio
                  index={4}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  duration={duration}
                  ticker={ticker}
                  rawData={rawData?.incomeStatement}
                  mode={mode}
                  lang={lang}
                />
              )}
            {rawData?.incomeStatement?.hist?.totalExpenses?.length > 0 && (
              <TotalExpenses
                index={14}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}

            {rawData?.incomeStatement?.hist?.revenue?.length > 0 &&
              rawData?.incomeStatement?.hist?.grossProfit?.length > 0 && (
                <GrossMargin
                  index={5}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  duration={duration}
                  ticker={ticker}
                  rawData={rawData?.incomeStatement}
                  mode={mode}
                  lang={lang}
                />
              )}
            {rawData?.incomeStatement?.hist?.netIncome?.length > 0 && (
              <NetIncome
                index={6}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {rawData?.incomeStatement?.hist?.ebitda?.length > 0 && (
              <Ebitda
                index={7}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {rawData?.cashflow?.hist?.freeCashFlow?.length > 0 && (
              <FreeCashFlow
                index={8}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.cashflow}
                mode={mode}
                lang={lang}
              />
            )}
            {rawData?.cashflow?.hist?.operatingCashFlow?.length > 0 && (
              <OperatingCashFlow
                index={9}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.cashflow}
                mode={mode}
                lang={lang}
              />
            )}
            {rawData?.incomeStatement?.hist?.eps?.length > 0 && (
              <Eps
                index={10}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.incomeStatement}
                mode={mode}
                lang={lang}
              />
            )}
            {rawData?.balanceSheet?.hist?.totalCash?.length > 0 &&
              rawData?.balanceSheet?.hist?.totalDebt?.length > 0 && (
                <CashVsDebt
                  index={11}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  duration={duration}
                  ticker={ticker}
                  rawData={rawData?.balanceSheet}
                  mode={mode}
                  lang={lang}
                />
              )}
            {rawData?.cashflow?.hist?.dividend?.length > 0 && (
              <Dividend
                index={12}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.cashflow}
                mode={mode}
                lang={lang}
              />
            )}

            {rawData?.balanceSheet?.hist?.sharesIssued?.length > 0 && (
              <SharesIssued
                index={13}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                duration={duration}
                ticker={ticker}
                rawData={rawData?.balanceSheet}
                mode={mode}
                lang={lang}
              />
            )}
            {/* <Ratios
              index={14}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              duration={duration}
            /> */}
          </Row>
        </div>
      </Stack>
    </>
  );
};

export default Chart;
