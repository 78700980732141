import { WebService, WebServiceMultipart } from './webServices';

export const setAccessTokenToHeader = (token) => {
  if (token) {
    WebService.defaults.headers.Authorization = `${token}`;
    WebServiceMultipart.defaults.headers.Authorization = `${token}`;
  } else {
    delete WebService.defaults.headers.Authorization;
    delete WebServiceMultipart.defaults.headers.Authorization;
  }
};

export const setCustomHeader = (value) => {
  if (value) {
    WebService.defaults.headers.common['user-id'] = `${value}`;
    WebServiceMultipart.defaults.headers.common['user-id'] = `${value}`;
  } else {
    delete WebService.defaults.headers.common['user-id'];
    delete WebServiceMultipart.defaults.headers.common['user-id'];
  }
};