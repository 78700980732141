export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol)
    : "0";
};

export const stringFormatter = (prefix = "", string, postfix = "") => {
  if (string === undefined || isNaN(parseFloat(string))) {
    return "-";
  }
  return `${prefix}${string}${postfix}`;
};

export const getRandomIDPrefix = () => {
  return Math.floor(100000 + Math.random() * 900000);
};
