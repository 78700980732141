import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { nFormatter, stringFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Data = ({
  ticker,
  tickerInfo,
  oneYearGrowth,
  fiveYearGrowth,
  lang,
  mode,
  _setMarketCap,
  _profitability,
  _growingRevenue,
  _growingNetProfit,
  _profitabilityAnnual,
  _growingRevenueAnnual,
  _growingNetProfitAnnual,
}) => {
  const [country, setCountry] = useState("US");
  const [loading, setLoading] = useState(true);
  const [marketCap, setMarketCap] = useState(null);
  const [fcfYield, setFcfYield] = useState(null);
  const [totalCash, setTotalCash] = useState(null);
  const [totalDebt, setTotalDebt] = useState(null);
  const [cashDebtRatio, setCashDebtRatio] = useState(null);
  const [pegRatio, setPegRatio] = useState(null);
  const [overallRisk, setOverallRisk] = useState(null);
  const [sector, setSector] = useState(null);
  const [payoutRatio, setPayoutRatio] = useState(null);
  const [dividendYield, setDividendYield] = useState(null);
  const [fiveYearAvgDividendYield, setFiveYearAvgDividendYield] =
    useState(null);
  const [fiftyTwoWeekHigh, setFiftyTwoWeekHigh] = useState(null);
  const [pricetosalestrailing12Months, setPricetosalestrailing12Months] =
    useState(null);
  const [priceToBook, setPriceToBook] = useState(null);
  const [trailingPE, setTrailingPE] = useState(null);
  const [profitability, setProfitability] = useState(null);
  const [growingRevenue, setGrowingRevenue] = useState(null);
  const [growingNetProfit, setGrowingNetProfit] = useState(null);
  const [profitabilityAnnual, setProfitabilityAnnual] = useState(null);
  const [growingRevenueAnnual, setGrowingRevenueAnnual] = useState(null);
  const [growingNetProfitAnnual, setGrowingNetProfitAnnual] = useState(null);

  useEffect(() => {
    setProfitability(_profitability);
  }, [_profitability]);
  useEffect(() => {
    setGrowingRevenue(_growingRevenue);
  }, [_growingRevenue]);
  useEffect(() => {
    setGrowingNetProfit(_growingNetProfit);
  }, [_growingNetProfit]);
  useEffect(() => {
    setProfitabilityAnnual(_profitabilityAnnual);
  }, [_profitabilityAnnual]);
  useEffect(() => {
    setGrowingRevenueAnnual(_growingRevenueAnnual);
  }, [_growingRevenueAnnual]);
  useEffect(() => {
    setGrowingNetProfitAnnual(_growingNetProfitAnnual);
  }, [_growingNetProfitAnnual]);

  useEffect(() => {
    let totalCash = tickerInfo?.totalCash;
    let totalDebt = tickerInfo?.totalDebt;
    let cashDebtRatio = (parseInt(totalCash) / parseInt(totalDebt)).toFixed(2);
    let freeCashflow = parseFloat(tickerInfo?.freeCashflow);
    let marketCap = parseFloat(tickerInfo?.marketCap);
    let fcfYield = (freeCashflow / marketCap) * 100;
    let dividendYield = parseFloat(tickerInfo?.dividendYield) * 100;

    setMarketCap(nFormatter(marketCap, 2));
    _setMarketCap(marketCap);
    setFcfYield(fcfYield.toFixed(2));
    setTotalCash(nFormatter(totalCash, 2));
    setTotalDebt(nFormatter(totalDebt, 2));
    setTotalDebt(nFormatter(totalDebt, 2));
    setCashDebtRatio(cashDebtRatio);
    setPegRatio(parseFloat(tickerInfo?.trailingPegRatio).toFixed(2));
    setOverallRisk(tickerInfo?.overallRisk);
    setSector(tickerInfo?.sector);
    setPayoutRatio(parseFloat(tickerInfo?.payoutRatio).toFixed(6));
    setDividendYield(parseFloat(dividendYield).toFixed(3));
    setFiveYearAvgDividendYield(tickerInfo?.fiveYearAvgDividendYield);
    setFiftyTwoWeekHigh(tickerInfo?.fiftyTwoWeekHigh);
    setPricetosalestrailing12Months(
      parseFloat(tickerInfo?.priceToSalesTrailing12Months).toFixed(3)
    );
    setPriceToBook(parseFloat(tickerInfo?.priceToBook).toFixed(3));
    setTrailingPE(parseFloat(tickerInfo?.trailingPE).toFixed(3));
    setLoading(false);
  }, [ticker, tickerInfo]);

  const getTitle = (type) => {
    return (
      <Col xs={9} className="data-value">
        {localString[lang][type]}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="info">
              {localString[lang].dataDescriptions[type]}
            </Tooltip>
          }
        >
          <i className="bi bi-info-circle m-2 font-1"></i>
        </OverlayTrigger>
      </Col>
    );
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Stack
          className={`content  ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <div className="d-flex flex-row flex-wrap bd-highlight data-container">
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">
                {localString[lang].FinancialMetrics}
              </div>
              <Row>
                {getTitle("MarketCap")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("$", marketCap, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("PE")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", trailingPE, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("PS")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", pricetosalestrailing12Months, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("PB")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", priceToBook, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("FCFYield")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", fcfYield, "%")}
                </Col>
              </Row>
              <Row>
                {getTitle("PEGRatio")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", pegRatio, "")}
                </Col>
              </Row>
            </div>
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">
                {localString[lang].QualityOfCompany}
              </div>
              <Row>
                {getTitle("OverallRisk")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", overallRisk, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("week52HighPrice")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("$", fiftyTwoWeekHigh, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("ProfitabilityAnnual")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    profitabilityAnnual === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {profitabilityAnnual}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingRevenueAnnual")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingRevenueAnnual === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {growingRevenueAnnual}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingNetProfitAnnual")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingNetProfitAnnual === "Yes"
                      ? "color-green"
                      : "color-red"
                  }`}
                >
                  {growingNetProfitAnnual}
                </Col>
              </Row>
              <Row>
                {getTitle("PriceGrowthoverthepast5Years")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", fiveYearGrowth, "%")}
                </Col>
              </Row>
              <Row>
                {getTitle("PriceGrowthoverthepast1Years")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", oneYearGrowth, "%")}
                </Col>
              </Row>
            </div>
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">{localString[lang].BalanceSheet}</div>
              <Row>
                {getTitle("Cash")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("$", totalCash, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("Debt")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("$", totalDebt, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("CashDebtRatio")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", cashDebtRatio, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("Profitability")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    profitability === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {profitability}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingRevenue")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingRevenue === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {growingRevenue}
                </Col>
              </Row>
              <Row>
                {getTitle("GrowingNetProfit")}
                <Col
                  xs={3}
                  className={`data-value text-end data-value-metric ${
                    growingNetProfit === "Yes" ? "color-green" : "color-red"
                  }`}
                >
                  {growingNetProfit}
                </Col>
              </Row>
            </div>
            <div className="p-2 bd-highlight flex-column">
              <div className="data-title">{localString[lang].Dividend}</div>
              <Row>
                {getTitle("DividendYield")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", dividendYield, "%")}
                </Col>
              </Row>
              <Row>
                {getTitle("PayoutRatio")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", payoutRatio, "")}
                </Col>
              </Row>
              <Row>
                {getTitle("FiveYearAvgDividendYield")}
                <Col xs={3} className="data-value text-end data-value-metric">
                  {stringFormatter("", fiveYearAvgDividendYield, "%")}
                </Col>
              </Row>
              {/* <Row>
                <Col sm={6} className="data-value">
                  {localString[LANG].DividendGrowthRateoverthepast5Years}
                </Col>
                <Col sm={6} className="data-value text-end data-value-metric">
                  TTT
                </Col>
              </Row> */}
            </div>
          </div>
        </Stack>
      )}
    </>
  );
};

export default Data;
