/* eslint-disable max-len */

import { THEMES } from "../constants";

export const THEME_COLORS = {
  [THEMES.DARK]: {
    graph: {
      title: "#fff",
      backgroundColor: "#000",
    },
    link: {
      selectedTextColor: "#6ea8fe",
      textColor: "#fff",
    },
  },
  [THEMES.LIGHT]: {
    graph: {
      title: "#000",
      backgroundColor: "#fff",
    },
    link: {
      selectedTextColor: "#0d6efd",
      textColor: "#000",
    },
  },
};
