import React, { useEffect, useState } from "react";
import Graph from "../home/Graph";
import { BILLION, COLORS, DURATION } from "../../constants";
import { localString } from "../../localization/localString";
import moment from "moment";

const GrossMargin = ({
  index,
  selectedId,
  setSelectedId,
  duration,
  ticker,
  rawData,
  lang,
  mode,
}) => {
  const [labels, setLabels] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [titleChange, setTitleChange] = useState(null);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let dateString = [];
      let data = [];

      for (let i = 0; i < rawData?.allDates?.length; i++) {
        if (
          rawData?.hist?.revenue[i] !== "" &&
          rawData?.hist?.revenue[i] !== null &&
          rawData?.hist?.grossProfit[i] !== "" &&
          rawData?.hist?.grossProfit[i] !== null
        ) {
          let dateFormat =
            duration === DURATION.QUARTERLY ? "[Q]Q-YYYY" : "YYYY";
          let thisDate = moment(rawData?.allDates[i]).format(dateFormat);
          let dataVal =
            (parseFloat(rawData?.hist?.grossProfit[i]) /
              parseFloat(rawData?.hist?.revenue[i])) *
            100;
          dateString.push(thisDate);
          data.push(dataVal.toFixed(2));
        }
      }

      let currentIndex = data?.length - 1;
      let firstIndex = currentIndex && currentIndex > 0 ? currentIndex - 1 : 0;

      let currentValue = data.length > 0 ? data[currentIndex] : "";
      let firstValue = data.length > 0 ? data[firstIndex] : "";

      let percentageChange =
        ((parseFloat(currentValue) - parseFloat(firstValue)) /
          Math.abs(parseFloat(firstValue))) *
        100;
      let val = isNaN(parseFloat(currentValue).toFixed(2))
        ? "-"
        : `${parseFloat(currentValue).toFixed(2)}%`;
      setTitleValue(val);
      setTitleChange(percentageChange);

      setLabels(dateString);
      setHistoricalData(data);
    }
  }, [ticker, duration, rawData]);

  const tooltipFormatter = (tooltipItem) => {
    return `${tooltipItem.formattedValue}%`;
  };

  const yaxisFormatter = (label) => {
    return `${label}%`;
  };

  const data = () => {
    return {
      labels,
      datasets: [
        {
          fill: true,
          data: historicalData,
          borderColor: COLORS.GRAPH.GROSS_MARGIN.borderColor,
          backgroundColor: COLORS.GRAPH.GROSS_MARGIN.backgroundColor,
        },
      ],
    };
  };

  return (
    <Graph
      index={index}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      chartType={"Bar"}
      data={data}
      description={localString[lang].description.GrossMargin}
      title={`${localString[lang].titles.GrossMargin}`}
      lang={lang}
      tooltipFormatter={tooltipFormatter}
      yaxisFormatter={yaxisFormatter}
      mode={mode}
      titleValue={titleValue}
      titleChange={titleChange}
      subTitle={
        duration === DURATION.QUARTERLY
          ? localString[lang].comparedToLastQuarter
          : localString[lang].comparedToLastYear
      }
    />
  );
};

export default GrossMargin;
