/* eslint-disable max-len */

// CONFIG constants
export const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "中文", // Chinese 1
    value: "ch",
  },
  {
    label: "Español", // Spanish 2
    value: "sp",
  },
  {
    label: "Français", // French 3
    value: "fr",
  },
  {
    label: "Deutsch", // German 4
    value: "gr",
  },
  {
    label: "Nederlands", // Dutch 5
    value: "du",
  },
  {
    label: "日本語", // Japanese 6
    value: "jp",
  },
  {
    label: "Italiana", // Italian 7
    value: "it",
  },
  {
    label: "한국어", // Korean 8 
    value: "kr",
  },
  {
    label: "Tiếng Việt", // Vietnamese 9
    value: "vt",
  },
  {
    label: "Bahasa Indonesia", // Bahasa Indonesia 10
    value: "bi",
  },
];

export const THEMES = {
  DARK: "dark",
  LIGHT: "light",
};

export const DURATION = {
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
};

export const COLORS = {
  GRAPH: {
    PRICE: {
      borderColor: "#d9730e",
      backgroundColor: "#f28e2a",
    },
    MARKET_CAP: {
      borderColor: "#489894",
      backgroundColor: "#489894",
    },
    REVENUE: {
      borderColor: "#a0cbe8",
      backgroundColor: "#a0cbe8",
    },
    REVENUE_VS_NET_INCOME: {
      borderColor: "#549d3a",
      backgroundColor: "#549d3a",
    },
    NET_INCOME: {
      borderColor: "#e2483d",
      backgroundColor: "#e2483d",
    },
    EBITDA: {
      borderColor: "#009bf5",
      backgroundColor: "#009bf5",
    },
    EPS: {
      borderColor: "#ffb902",
      backgroundColor: "#ffb902",
    },
    TOTAL_EXPENSES: {
      borderColor: "#9c27b0",
      backgroundColor: "#9c27b0",
    },
    SHARES_ISSUED: {
      borderColor: "#fd7f6f",
      backgroundColor: "#fd7f6f",
    },
    FREE_CASH_FLOW: {
      borderColor: "#0bb4ff",
      backgroundColor: "#0bb4ff",
    },
    OPERATING_CASH_FLOW: {
      borderColor: "#f46a9b",
      backgroundColor: "#f46a9b",
    },
    DIVIDEND: {
      borderColor: "#549d3a",
      backgroundColor: "#87bc45",
    },
    GROSS_MARGIN: {
      borderColor: "#00b7c7",
      backgroundColor: "#00b7c7",
    },
    TOTAL_DEBT: {
      borderColor: "#ef9b20",
      backgroundColor: "#ef9b20",
    },
    TOTAL_CASH: {
      borderColor: "#00b7c7",
      backgroundColor: "#00b7c7",
    },
  },
};

export const LANG = LANGUAGES[0].value;

export const BILLION = 1000000000;
export const MILLION = 1000000;
export const NULL_TICKER = -99999;
