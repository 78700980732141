import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Data from "./Data";
import Chart from "./Chart";
import "./home.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { localString } from "../../localization/localString";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Home from "./Home";
import { NULL_TICKER } from "../../constants";
import { THEME_COLORS } from "../../colors";

const HomeTabs = ({
  allTickers,
  lang,
  mode,
  starredTickers,
  updateStarred,
}) => {
  const { id } = useParams();
  const [tabList, setTabList] = useState([]);
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    let lsTabList = JSON.parse(localStorage.getItem("tabList"));
    let thisTabList =
      lsTabList === null || lsTabList?.length === 0
        ? [
            {
              title: "New Tab",
              ticker: undefined,
            },
          ]
        : lsTabList;

    let itemIndex = thisTabList?.findIndex((o) => o.ticker === id);

    if (itemIndex === -1) {
      thisTabList.push({
        title: "New Tab",
        ticker: id,
      });
      setActiveKey(thisTabList.length - 1);
    } else {
      setActiveKey(itemIndex);
    }
    setTabList(thisTabList);
  }, []);

  // useEffect(() => {
  //   localStorage.setItem("tabList", JSON.stringify(tabList));
  // }, [tabList]);

  const handleSelect = (key) => {
    if (key === "plus") {
      let newTabList = tabList;
      newTabList.push({ title: "New Tab", ticker: NULL_TICKER });
      setTabList(JSON.parse(JSON.stringify(newTabList)));
      setActiveKey(newTabList.length - 1);
    } else {
      setActiveKey(key);
    }
  };

  const setTabTitle = (key, title, ticker) => {
    let newTabList = tabList;
    newTabList[key].title = title;
    newTabList[key].ticker = ticker;
    setTabList(JSON.parse(JSON.stringify(newTabList)));
    localStorage.setItem("tabList", JSON.stringify(newTabList));
  };

  const deleteTab = (e, id) => {
    e.stopPropagation();
    if (id === tabList?.length - 1) {
      setActiveKey(tabList?.length - 2);
    } else if (id === 0) {
      setActiveKey(0);
    } else {
      setActiveKey(id - 1);
    }
    let newTabList = tabList;
    newTabList.splice(id, 1);
    setTabList(JSON.parse(JSON.stringify(newTabList)));
    localStorage.setItem("tabList", JSON.stringify(newTabList));
  };
  return (
    <>
      <Tabs
        id="controlled-tab-example"
        className="mb-3 tabs-container"
        activeKey={activeKey}
        onSelect={handleSelect}
      >
        {tabList.map((item, index) => (
          <Tab
            key={index}
            eventKey={index}
            title={
              <span
                style={{
                  color: THEME_COLORS[mode].link.textColor,
                }}
              >
                {item.title}
                {tabList?.length > 1 && (
                  <i
                    onClick={(e) => deleteTab(e, index)}
                    className="bi bi-x ms-2"
                  ></i>
                )}
              </span>
            }
          >
            <Home
              allTickers={allTickers}
              lang={lang}
              mode={mode}
              starredTickers={starredTickers}
              updateStarred={updateStarred}
              compare={false}
              setTabTitle={setTabTitle}
              tabKey={index}
              tabTicker={item.ticker}
            />
          </Tab>
        ))}
        <Tab
          eventKey="plus"
          title={
            <span>
              <i className="bi bi-plus-square-fill"></i>
            </span>
          }
        ></Tab>
      </Tabs>
      {/* <span><i className="bi bi-plus-square-fill"></i></span> */}
    </>
  );
};

export default HomeTabs;
