import React from "react";
import "./home.scss";

const ScrollToTop = () => {
  const handleScroll = () => {
    document.getElementById("stock-penguins-section").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <i
        onClick={handleScroll}
        className="bi bi-arrow-up-square-fill scroll-to-top"
      ></i>
    </>
  );
};

export default ScrollToTop;
